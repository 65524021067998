import React, {useEffect} from "react";
import { url as base} from "./Utils";
import {Button, Typography} from "antd";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import log from 'loglevel';
import { useLocation, useHistory } from "react-router-dom";
import { RobotOutlined } from '@ant-design/icons';
import { SQPage } from "./Components";

const { Text } = Typography;


function AutomateByParameter() {

  const url = useLocation().pathname;

  log.info("Automate URL " + JSON.stringify(url));

  const substrings = url.split('/');
  const id = substrings[2];
  log.info("Automate ID " + id);

  const inputs={};

  for (var index = 3; index < substrings.length; index++) {

    log.info("Automate checking param " + substrings[index]);
    inputs[substrings[index]] = "<YOUR VALUE>";
  }

  return (
    <SQPage>
      <Automate inputs={inputs} sqid={id} />
    </SQPage>
  ); 
}

function Automate(props) {
  log.info("Automate:" + JSON.stringify(props));
  log.info("Automate base " + JSON.stringify(base));
  const history = useHistory();

  useEffect(() => {

    function checkEscape(event) {
      if(event.keyCode === 27 && props.setShowAutomation !== undefined) {
        props.setShowAutomation(false);
        //Do whatever when esc is pressed
      }  
    }

    document.addEventListener("keydown", checkEscape, false);

    return () => {
      document.removeEventListener("keydown", checkEscape, false);
    };

  }, [props]);



  var cmd = "curl ";
  var replace = "<insert ID Token JWT here>";

  var fileContent = '{"sqidentity": {"username": "<YOUR EMAIL>",\n"password": "<YOUR PASSWORD>"},\n"sqid": "' + props.sqid + '"';
  //,\n "inputs:{' + inputs + '}}';

  if (Object.keys(props.inputs).length > 0) {

    cmd = cmd + "-d '" + JSON.stringify(props.inputs) + "'";
    fileContent = fileContent + ",\n" + '"inputs":' +  JSON.stringify(props.inputs);
  }

  fileContent = fileContent + "\n}";
  cmd = cmd + " -H 'Authorization: Bearer <insert ID Token JWT here>' -H 'Content-Type: application/json' -X POST '" + base + "runSequenceAuthenticated?sequence_id=" + props.sqid + "'";
  const json = "const json = " + fileContent + "\n";


  return (
      <div>
        <div style={{  }}>
          <div className="SQPage">
            <h1><RobotOutlined style={{marginRight:"20px", verticalAlign: "middle"}} />Automate....</h1>

            <h3>...with SequenceAPI from the command line:</h3>
            <p>This is the simplest way to integrate tests into build scripts or CI pipes.</p><br/>
            <ul>
              <li>Install the Sequence API node module as global</li>
              <Text code> npm install -g sequenceapi</Text>
              <br/><br/><li>Optionally create a file with your credentials and the sqid:</li>
              <Text code>
                {fileContent}
              </Text>
              <br/><br/><li>You will be prompted for missing parameters, so you can leave password out. Run the command:</li>

              <Text code>sqapi -f ~/Desktop/somefile.json</Text>

              <br/><br/><li>Alternatively, run without -f and you will be prompted for the values.</li>

            </ul>

            <h3>...with SequenceAPI from your code:</h3>

            <p>The advantage with this approach is you can programatically build the inputs 
              that you send to the sequence such as credential tokens.</p><br/>
            <ul>
              <li>Install the Sequence API node module in your project.</li>
              <Text code> npm install sequenceapi</Text>
              <br/><br/><li>Add the dependency to your code e.g. ... </li>
              <Text code>const seq = require('sequenceapi');</Text>
              <br/><br/><li>Run the sequence from code e.g. ...</li>
              <Text code>{json}<br/><br/>seq.runSequenceFromJSON(json);</Text>
            </ul>            

            <h3>...with curl:</h3>
            <p>Take care to replace the parts of the command in angle brackets e.g. '{replace}'.</p><br/>
            <Text code>{cmd}</Text>

            <br />
            <div style={{marginTop:"30px"}}>

              <Button
                type="link"
                htmlType="submit"
                onClick={e => {
                  if (props.setShowAutomation !== undefined) props.setShowAutomation(false);
                  else history.goBack();
                }}
                >
                  Close
              </Button>
            </div>

          </div>
        </div>
      </div>
  ); 
}
export {Automate, AutomateByParameter};
