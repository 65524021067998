
import {  SettingTwoTone, CodeTwoTone, CloudTwoTone } from '@ant-design/icons';

const defaultPage = {
  url: "/",
  primary: {
    h1: "Build workflows with chained API calls.",
    h2: <>Test output, response codes & latency against goals. <br></br>Direct API output to webhooks.</>,
    items: [
      <><CloudTwoTone />&nbsp;&nbsp;No software to install. Cloud + browser or headless.</>,
      <><CodeTwoTone />&nbsp;&nbsp;Simple to write (JSON).</>,
      <><SettingTwoTone />&nbsp;&nbsp;Simple to automate (API, npm, cli, Git Actions).</>
    ]
  },
  secondary: {
    first: {
      header: "Accelerate serverless",
      paragraphs: [
        "Serverless is painful to iteratively develop. Building the right dataset and pushing it back through the API that wraps the function can require other API calls.",
        "Automate the workflow or build dashboards where you trigger them."
      ]
    },
    second: {
      header: "Powerful API Testing",
      paragraphs: [
        "Model real world scenarios better by defining workflows across multiple API calls. Test valid and invalid responses against your goals.",
        "Move parameters (e.g. headers, url) and the goals into inputs, so the same workflow tests 200 and 400 responses.",
        "Automate and receive email on fail."
      ]
    },
    third: {
      header: "Simplify low code",
      paragraphs: [
        "Using JSON, specify outputs from one API call, as inputs into subsequent calls. Automate the trigger and direct the final output to a webhook.",
        "Embed public sequences that do useful things like construct tokens. Publish your own sequences for others to use."
      ]
    }
  }
}

const securityPage = {
  url: "/build-better-security-tests-for-your-api",
  primary: {
    h1: "API security testing for the real world",
    h2: <>Get beyond tests that call single endpoints. <br></br>Chain calls to model real scenarios.</>,
    items: [
      <><CloudTwoTone />&nbsp;&nbsp;No software to install. Cloud + browser or headless.</>,
      <><CodeTwoTone />&nbsp;&nbsp;Simple to write (JSON).</>,
      <><SettingTwoTone />&nbsp;&nbsp;Simple to automate (API, npm, cli, Git Actions).</>
    ]
  },
  secondary: {
    first: {
      header: "Fast to build",
      paragraphs: [
        "Write your tests in JSON. Connect to your CI/CD pipeline, schedule to run, or run manually. Receive emails when tests fail.",
        "Reuse tests across all your environments, from production to development."
      ]
    },
    second: {
      header: "Better coverage",
      paragraphs: [
        "Model real world scenarios better by defining workflows across multiple API calls. Test valid and invalid responses against your goals.",
        "Move parameters (e.g. headers, url) and the goals into inputs, so the same workflow tests 200 and 400 responses.",
        "Automate and receive email on fail."
      ]
    },
    third: {
      header: "Peace of mind",
      paragraphs: [
        "Once your tests are in place, you can forget about them until new code, releases, or third party libraries break them and you receive an email." ,
        "The boss is gonna like that!"    
      ]
    }
  }
}

const cloudPage = {
  url: "/cloud-based-api-test-platform",
  primary: {
    h1: "Cloud based API test platform",
    h2: <>No need to install clunky software. <br></br>Build and run in a browser or through our API/CLI.</>,
    items: [
      <><CloudTwoTone />&nbsp;&nbsp;No software to install. Cloud + browser or headless.</>,
      <><CodeTwoTone />&nbsp;&nbsp;Simple to write (JSON).</>,
      <><SettingTwoTone />&nbsp;&nbsp;Simple to automate (API, npm, cli, Git Actions).</>
    ]
  },
  secondary: {
    first: {
      header: "Fast to build",
      paragraphs: [
        "Write your tests in JSON, either in the browser or your IDE. Run manually, or connect to your CI/CD pipeline, or schedule them.",
        "Reuse tests across all your environments, from production to development."
      ]
    },
    second: {
      header: "Better coverage",
      paragraphs: [
        "Model real world scenarios better by defining workflows across multiple API calls. Test valid and invalid responses against your goals.",
        "Move parameters (e.g. headers, url) and the goals into inputs, so the same workflow tests 200 and 400 responses.",
        "Automate and receive email on fail."
      ]
    },
    third: {
      header: "Tests run in the cloud",
      paragraphs: [
        "Whether your tests are autmated or manual, they run securely in the cloud. You can monitor results in the dashboard or receive failure notifications by email or webhook." ,
        "You can even use secrets to hide secure credentials. Your boss is gonna like that!"    
      ]
    }
  }
}

const cloudPageR1 = { ...cloudPage, url: "/cloud-based-api-test-platform-r1"}
const cloudPageR2 = {...cloudPage, url: "/cloud-based-api-test-platform-r2"}

const complexPage = {
  url: "/test-complex-api-scenarios",
  primary: {
    h1: "Test complex API scenarios.",
    h2: <>Build tests that link multiple API calls. <br></br>Pass output as input between endpoints.</>,
    items: [
      <><CloudTwoTone />&nbsp;&nbsp;No software to install. Cloud + browser or headless.</>,
      <><CodeTwoTone />&nbsp;&nbsp;Simple to write (JSON).</>,
      <><SettingTwoTone />&nbsp;&nbsp;Simple to automate (API, npm, cli, Git Actions).</>
    ]
  },
  secondary: {
    first: {
      header: "Fast to build",
      paragraphs: [
        <>Write your tests in JSON. <a href="https://blog.sequenceapi.com/automating-sequences/" style={{color:"orange"}}>Connect</a> to your CI/CD pipeline, schedule to run, or run manually. Receive emails when tests fail.</>,
        "Reuse tests across all your environments, from production to development."
      ]
    },
    second: {
      header: "Better coverage",
      paragraphs: [
        "Model real world scenarios better by defining workflows across multiple API calls. Test valid and invalid responses against yuor goals.",
        "Move parameters (e.g. headers, url) and the goals into inputs, so the same workflow tests 200 and 400 responses.",
        "Automate and receive email on fail."
      ]
    },
    third: {
      header: "Rich environment",
      paragraphs: [
        <>SequenceAPI provides a deep set of capabilities for testing APIs: 
          Variables, <a href="https://blog.sequenceapi.com/secrets/" style={{color:"orange"}}>secrets, </a>
          <a href="https://blog.sequenceapi.com/mocking-api-calls/" style={{color:"orange"}}>mocks, </a>
          <a href="https://blog.sequenceapi.com/automating-sequences/" style={{color:"orange"}}>automation, </a>
          status and output goals, <a href="https://blog.sequenceapi.com/sending-output-to-a-webhooks/" style={{color:"orange"}}>webhooks</a>, web files for JSON input
        </>       
      ]
    }
  }
}



const landingPages = {
  default: defaultPage,
  extra: [
    securityPage,
    cloudPage,
    cloudPageR1,
    cloudPageR2,
    complexPage
  ]
};

export default landingPages;
