import React, {useState, useEffect} from "react";
import { Row, Col } from "reactstrap";
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { ScrollView } from '@aws-amplify/ui-react';
import {fetchSequence, checkSequence} from "../../../../Utils";
import {json /*, jsonParseLinter*/} from "@codemirror/lang-json"
import CodeMirror from '@uiw/react-codemirror';
//import { EditorView } from "@codemirror/view";
//import { EditorState } from "@codemirror/state";
import {basicSetup} from "@codemirror/basic-setup"

import {
  Button,
  Carousel, 
  Alert,
  Tag,
  Spin,
  Tabs
} from "antd";
import log from 'loglevel';

import Endpoints from "../../../../Run";

const { TabPane } = Tabs;


const Editor = () => {
  const [tutorials, setTutorials] = useState([]);
  const myRef = React.createRef();

  useEffect(() => {

    const sqs = JSON.parse(process.env.REACT_APP_TUTORIALS_CAROUSEL);
    setTutorials(sqs);
    return () => {
    };
  }, []);

  return (
      <>
        <h3 style={{color:"#F9F7F7", marginTop:"10px", marginLeft:"10px"}}>
          Take 30 seconds out to try SequenceAPI here...
        </h3> 
    
        <Row>
          <Col>
            <div className="front mx-auto" >
                <Carousel ref={myRef} infinite={false}>
                  {tutorials.map(tutorial => <TutorialFooter key={tutorial.id} id={tutorial.id} carousel={myRef} label={tutorial.name} />)} 
                </Carousel>
              <div className="notch" />
            </div>
          </Col>
        </Row>
        <p style={{textAlign: "right", color:"#F9F7F7", paddingRight:"10px", marginTop:"0px", marginBottom:"0px"}}>
          You can view the entire collection {" "}
          <a style={{fontWeight:"bold", color:"white"}} href="https://sequenceapi.com/collection?id=ba7582">
            here
          </a>.
        </p>
      </>
  );
};

function TutorialFooter(props) {

  function previous() {
    props.carousel.current.prev();
  }

  function next() {
    props.carousel.current.next();
  }

  return (
    <div >
      <Row className="align-items-center" justify="center">
        <Col>
          <Button type="text" onClick={previous} style={{color:"#F9F7F7"}}>
            <LeftOutlined />
          </Button>
        </Col>
 

        <Col style={{color:"#F9F7F7"}} className="bold">{props.label}</Col>
        <Col>
          <Button type="text" onClick={next} style={{color:"#F9F7F7"}}>
            Next 
            <RightOutlined />
          </Button>
        </Col>
      </Row>
        <div style={{
                    background:"rgba(255,255,255,.9)", 
                    boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", 
                    borderRadius: "5px", 
                    padding: "15px",
                    marginRight: "15px",
                    marginLeft: "15px",
                    marginTop: "3px",
                    marginBottom: "5px"}}>
          <Playground2 id={props.id} />
        </div>

    </div>

  );
}

function Playground2(props) {

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    fetchSequence(props.id).then(function(value) {

      log.info("fetched: " + JSON.stringify(value.data.Template));
      
      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: value.data.vTemplate.dependencies.required,
              secrets: value.data.vTemplate.dependencies.secrets,
              includesMocks: value.data.vTemplate.includesMocks
      });
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      setLoading(false);
    });   

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);

  return (
    <>
      {
        (loading) 
        ? 
          <Spin />
        : 
          <div>
            <h2>{data.template.name}</h2>
            <p>
            {data.template.description}{"   "}
            </p>
            <p>
            Edit the JSON and try running.
            </p>
            <ToggleEditor
                  id={props.id}
                  template={data.template}
                  rTemplate={data.rTemplate}
                  required={data.required}
                  secrets={data.secrets}
                  isSaved={false}
                  includesMocks={data.includesMocks}
          />
          </div>

      }
      
    </>
  ); 
}

function ToggleEditor(props) {

  log.info("Editor init " + JSON.stringify(props.rTemplate.graph) );

  const [orderedTemplate, setOrderedTemplate] = useState({name:props.template.name,
                                                          description: props.template.description,
                                                          dictionary: props.template.dictionary,
                                                          outputs: props.template.outputs,
                                                          endpoints: props.template.endpoints}); 

  const [data, setData] = useState({template: orderedTemplate, 
                                    name: orderedTemplate.name, 
                                    description: orderedTemplate.description, 
                                    secrets: props.secrets,
                                    required: props.required, 
                                    rTemplate: props.rTemplate,
                                    includesMocks: props.includesMocks});
        
  const [newData, setNewData] = useState({text: orderedTemplate, isValid: false, isChecked: true});                                  
  const [error, setError] = useState({visible: false, text:""});
  const [checking, setChecking] = useState(false);

  useEffect(() => {

    setOrderedTemplate({name:props.template.name,
                        description: props.template.description,
                        dictionary: props.template.dictionary,
                        outputs: props.template.outputs,
                        endpoints: props.template.endpoints}
                      );

    setData({template: orderedTemplate, 
            name: orderedTemplate.name, 
            description: orderedTemplate.description, 
            secrets: props.secrets,
            required: props.required, 
            rTemplate: props.rTemplate,
            includesMocks: props.includesMocks
          }
    );

    setNewData({text: orderedTemplate, isValid: false, isChecked: true});  

    return () => {
      
    };
  }, [props.updated]);

  const handleClose = () => {
    setError({visible: false, text: ""});
  };

  function doCheck() {
    setChecking(true);
    setError({visible: false, text: ""});
    checkSequence({template: newData.text})
    .then(function(value) {
      //log.info("Got load response:" + JSON.stringify(value.data));

      setData({
              name: value.data.name, 
              description: value.data.description, 
              secrets: value.data.secrets,
              required: value.data.required,
              rTemplate: value.data.template,
              template: newData.text,
              includesMocks: value.data.includesMocks
            });

      setNewData({
              text: data.template,
              isValid:false,
              isChecked: true
            });
      //log.info("doCheck:" + JSON.stringify(data));
      //setDrawerVisible(true);

      if (props.onValidation !== undefined) props.onValidation(newData.text);
    })
    .catch(function(err) {
      log.error(err);
      log.info("ERROR: " + JSON.stringify(err.response.data));
      setError({visible: true, text: JSON.stringify(err.response.data.reason)});
      if (props.onInvalidation !== undefined) props.onInvalidation();
    })
    .finally(function() { 
      setChecking(false);
    });  
  }

  return (

      <div className="card-container"  >

        <Tabs defaultActiveKey="1" type="card" style={{height:"500px"}}>

          <TabPane tab="Run" key="1">
            
              <Endpoints  secrets={data.secrets} inputs={data.required} template={data.rTemplate} required={data.required} name={data.name} 
                    isSaved={props.isSaved} sqid={props.sqid} />      
          </TabPane>

          <TabPane tab="Edit" key="3">
            <div>
              {
                error.visible 
                ? <Alert message={error.text} type="error" closable afterClose={handleClose} />
                : null
              }
              <Row gutter={[16, 16]}>
                <Col>
                  <Button style={{marginRight:"10px"}} type="primary" htmlType="submit" onClick={doCheck} loading={checking} disabled={!newData.isValid}>
                    Check
                  </Button>  
                  {data.includesMocks ? <Tag style={{marginLeft:"10px"}} color="warning">Includes mocks</Tag> : null}
                </Col>
              </Row>   
              <ScrollView height="400px" style={{marginBottom:"5px", marginTop:"5px", borderStyle:"solid", borderWidth:"1px", borderColor:"lightgrey"}}>

              <div >

                  <CodeMirror
                    value={JSON.stringify(orderedTemplate, null, 2)}
                    extensions={[
                                  basicSetup,
                                  json()
    
                                ]
                    }
                    onChange={(value) => {

                      try {
                        const parsedText = JSON.parse(value);
                        setNewData({text: parsedText, isValid: true, isChecked: false});  
                        //setData({...data, template: parsedText, isValid: true});
                      }
                      catch(err) {
                        //log.info("failed: " );
                        //notify(false, v.state.doc, v);
                        setNewData({text: value, isValid: false, isChecked: false});  

                        //setData({...data, isValid: false});
                      }
                    }}
                  />              
              </div>   
              </ScrollView>

            </div>      
          </TabPane>

        </Tabs>
        </div>


  ); 
}

export default Editor;
