import React from "react";
import Editor from "./components/Editor.jsx";
import DefaultFooter from "./components/DefaultFooter.jsx";
import { Card } from 'antd';
import { Row, Col} from "reactstrap";
import {LoginDrawer} from "../../../Components.js";

// alternative 1 page components
import Heading from "./components/Heading.jsx";
import Subscribe from "./components/Subscribe.jsx";
import Proposal from "./components/Proposal.jsx";
import FeaturesCantMiss from "./components/FeaturesCantMiss.jsx";

// global template script file
import "pe7-icon/dist/dist/pe-icon-7-stroke.css";
import "../assets/sass/laapp.scss";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faTwitter
} from "@fortawesome/free-brands-svg-icons";

import {
  faEnvelope,
  faUpload,
  faSmileBeam
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faTwitter,
  faEnvelope,
  faSmileBeam,
  faUpload
);

function Index (props) {
  return (
    <>
      <main >
        {/* Alternative 1 specific components */}
        <div style={{paddingLeft:"20px", paddingRight:"20px"}}>
          <Heading message={props.messages.primary} />
        </div>
        
        <Row  gutter={[16, 16]} style={{paddingTop:"20px", paddingBottom:"40px", paddingLeft:"20px", paddingRight:"20px"}}>
          <MessageCard message={props.messages.secondary.first} signUpSource={props.signUpSource} showSignUp={false} />
          <MessageCard message={props.messages.secondary.second} signUpSource={props.signUpSource} showSignUp={true} />
          <MessageCard message={props.messages.secondary.third} signUpSource={props.signUpSource} showSignUp={false} />
        </Row>
        <div style={{background:"rgba(44,46,49,.99)", padding: "5px"}}>
          <Editor />            
        </div>

        <Proposal />
        <FeaturesCantMiss />
        <Subscribe signUpSource={props.signUpSource}/>
      </main>
      <DefaultFooter />
    </>
  );
}

function MessageCard(props) {

  var key  = 0;

  return (
    <Col span={8}>
      <Card title={<h3>{props.message.header}</h3>} style={{ marginBottom:"5px",boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)" }} >
        <div style={{color:"#6C757D", fontSize:"15px"}}>
          {props.message.paragraphs.map(paragraph => (
              <p key={key++}>
                {paragraph}
              </p>   
            )
          )} 
          {
            (props.showSignUp)
            ?
              <LoginDrawer  style={{marginRight: "10px"}} 
              key="l1" 
              buttonType="danger" 
              buttonText="Sign Up Free" 
              signUpSource={props.signUpSource+"/" + props.message.header}
              auth='signUp' /> 
            :
            <a href="https://blog.sequenceapi.com/sequence-api-concepts-an-overview/" style={{color:"orange"}}>Learn More</a>
          } 
        </div>
      </Card>
    </Col>
  );
}

export default Index;
