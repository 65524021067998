import React, {useState, useEffect, useContext} from "react";
import { fetchSequence, getPublishedState } from "./Utils";

import { Button, Spin, Typography } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { CodeSandboxOutlined } from '@ant-design/icons';
import { ShareField } from "./Publish";
import { PublishProvider, PublishContext} from "./PublishContext";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import log from 'loglevel';

import { SQPage, Editor } from "./Components";
const { Title } = Typography;

function PlaygroundByRecursion() {

  const { url } = useRouteMatch();
  const substrings = url.split('/');
  const key = substrings[2];

  return (
    <PublishProvider>
      <Playground id={key} />
    </PublishProvider>
  ); 
}

function PlaygroundByParameter() {

  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");

  return (
    <PublishProvider>
      <Playground id={key} />
    </PublishProvider>  ); 
}

function Playground(props) {

  return (

    <SQPage>
      <h1><CodeSandboxOutlined style={{marginRight:"20px", verticalAlign: "middle"}} />Playground</h1>
      <Playground2 id={props.id}/>
    </SQPage>
  ); 
}

function Playground2(props) {

  const history = useHistory();

  const sqURL = "/sequence?id=" + props.id;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [, setPublishState] = useContext(PublishContext); 

  useEffect(() => {

    fetchSequence(props.id).then(function(value) {

      log.info("fetched: " + JSON.stringify(value.data.Template));
      
      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: value.data.vTemplate.dependencies.required,
              secrets: value.data.vTemplate.dependencies.secrets,
              includesMocks: value.data.vTemplate.includesMocks
      });

      setPublishState(getPublishedState(value.data));

      if (props.showDetails !== undefined) setShowDetails(props.showDetails);
      else setShowDetails(true);
    })
    .catch(function(error) {
      console.error(error);
      setNotFound(true);
    })      
    .finally(function() { 
      setLoading(false);
    });   

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);


  var extra = <Button type="link" 
                htmlType="submit"                               
                onClick={e => {
                  e.preventDefault();
                  history.push( sqURL );
                }}>
              Go to source sequence
              </Button>  

  return (

    <>
      {notFound 
      ?
        <div>
          <br></br>
          <h2>Could not find sequence {props.id}</h2>
        </div>
      :
        <div>
          {
            (showDetails) ?
            <div>
              <Title level={5}>{data.template.name}</Title>
              <p>
              {data.template.description}{"   "}<ShareField id={props.id} type="p" />
              </p>
              <p>
              Edit the JSON and try running.
              </p>
            </div>
            : <p>Edit the JSON and try running</p>
          }
          
          <br />
          {
            (loading) 
            ? <Spin />
            : <Editor extra={extra} 
                      id={props.id}
                      template={data.template}
                      rTemplate={data.rTemplate}
                      required={data.required}
                      secrets={data.secrets}
                      isSaved={false}
                      includesMocks={data.includesMocks}
              />
          }
        </div>
      }
    </>
  ); 
}

export {PlaygroundByRecursion, PlaygroundByParameter, Playground2};
