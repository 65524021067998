import React from "react";
import {
  Button,
  Spin,
  Tag,
  Menu, 
  Dropdown,
  Row,
  Col,
  Divider
} from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SQPage, SQButton, AddToCollectionButton } from "./Components";
import { CopyOutlined, DeleteOutlined, CodeSandboxOutlined, UserOutlined, EditOutlined, RobotOutlined } from '@ant-design/icons';
import { PublishTag, ShareField } from "./Publish";
import { PublishProvider, PublishContext} from "./PublishContext";

import "antd/dist/antd.less";

import "antd/dist/antd.css";
import "./App.css";
import { useState, useEffect, useContext} from "react";

import { fetchSequence, cloneSequence, deleteSequence, getPublishedState } from "./Utils";
import { CredentialsContext } from "./CredentialsContext";

import Endpoints from "./Run";
import log from 'loglevel';

function SequenceByRecursion () {

  const { url } = useRouteMatch();
  const substrings = url.split('/');
  const id = substrings[2];

  return (
    <PublishProvider>
      <Sequence id={id}/>
    </PublishProvider>
    
  ); 
}

function SequenceByParameter () {

  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  return (
    <PublishProvider>
      <Sequence id={id}/>
    </PublishProvider>
  ); 
}

function Sequence(props) {

  const key = props.id;

  //log.info("Test:" + JSON.stringify(props));

  const [data, setData] = useState({mine: false, author: "", loaded: false, name: "", description: "", secrets: [], required: [], rTemplate: {endpoints:[], graph:[]}});
  const context = useContext(CredentialsContext);
  const [publishedState, setPublishedState] = useState({});
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {

    fetchSequence(key).then(function(value) {
      //log.info("Sequence:" + JSON.stringify(value.data));
      //log.info("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required));
      //log.info("Run:" + JSON.stringify(value.data.rTemplate));

      const orderedTemplate = {};
      orderedTemplate.name = value.data.Template.name;
      orderedTemplate.description = value.data.Template.description;
      orderedTemplate.dictionary = value.data.Template.dictionary;
      orderedTemplate.outputs = value.data.Template.outputs;
      orderedTemplate.endpoints = value.data.Template.endpoints;

      var mine = (value.data.Author === context);
      var url = "/a/" + key;
      const inputs = value.data.vTemplate.dependencies.required;

      for (var index = 0; index < inputs.length; index++) {
        url = url + "/" + inputs[index];
      }
      setData({name: value.data.vTemplate.name, 
              description: value.data.vTemplate.description, 
              required: inputs,
              secrets: value.data.vTemplate.dependencies.secrets,
              rTemplate: value.data.rTemplate,
              template: orderedTemplate,
              loaded: true,
              author: value.data.user['custom:display-name'],
              mine: mine,
              automateURL: url});

      setPublishedState(getPublishedState(value.data));


    })
    .catch(function(error) {
      log.error(error);
      setNotFound(true);
    });

    return () => {
      // Clean up the subscription
    };
  }, [key, context]);

  return (
    <SQPage>
      
      {notFound 
      ?
        <div>
          <br></br>
          <h2>Could not find sequence {key}</h2>
        </div>
      :
        <>
          {data.loaded
          ?  
            <>
              {data.mine 
              ? <Tag><UserOutlined style={{marginRight:"20px"}} />You created this sequence</Tag> 
              : <Tag><UserOutlined style={{marginRight:"20px"}} />{data.author}</Tag>
              } 
              <h1><img alt="sequence logo" src="logo_black.png" width="2.5%" style={{marginRight:"20px", verticalAlign: "middle"}}/>{data.name}</h1>
              <p>{data.description}{"   "}<ShareField id={props.id}/></p>
              <Row justify="end">
                <Buttons1 publishedState={publishedState}/>
              </Row>
              <Divider />
              <br />
              <Row justify="end">
                <Buttons2 isMine={data.mine} id={key} publishedState={publishedState} automateURL={data.automateURL}/>
              </Row>
              <Row>
                <Col span={12}>
                  <Endpoints inputs={data.required} template={data.rTemplate} sqid={key} name={data.name} isSaved={true}/>
                </Col>
              </Row>
            </>
          : 
            <div style={{marginLeft: "20px"}}>
              <Spin size="large" />  
            </div>      
          }
        </>
      }
    </SQPage>
  ); 
}

function Buttons1(props) {

  const [, setPublishState] = useContext(PublishContext); 

  useEffect(() => {

    setPublishState(props.publishedState);
    return () => {
      // Clean up the subscription
    };
  }, [props.publishedState]);

  return (
    <Col>
      <PublishTag />
    </Col>
  );
}
function Buttons2(props) {

  const key = props.id;
  const [cloning, setCloning] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const playgroundURL = "/playground?id=" + key;
  const history = useHistory();

  function clone() {
    setCloning(true);

    cloneSequence(key).then(function(value) {
      //log.info("/sequence?id=" + value.data.sequence_id);
      history.push( "/sequence?id=" + value.data.sequence_id );
      //log.info("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setCloning(false);
    });
  }

  function deleteSq() {
    setDeleting(true);

    deleteSequence(key).then(function(value) {
      //log.info("/sequence?id=" + value.data.sequence_id);
      history.push( "/mysequences");
      //log.info("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setDeleting(false);
    });
  }

  function edit() {
    history.push( "/edit?id=" + key );
  }

  function menuClick(item) {
    log.info("menu key:" + item.key);

    if (item.key === "0") {
      history.push( playgroundURL );
    }
    else if (item.key === "1") {
      clone();
    }
    else if (item.key === "3") {
      deleteSq();
    }
    else if (item.key === "4") {
      history.push(props.automateURL);
    }
  }

  const menu = (
    <Menu onClick={menuClick}>
      <Menu.Item key="0" icon={<CodeSandboxOutlined />}>Open in Playground</Menu.Item>
      <Menu.Item key="1" icon={<CopyOutlined />}>Clone</Menu.Item>
      <Menu.Item disabled={deleting} key="3" icon={<DeleteOutlined />}>Delete</Menu.Item>
      <Menu.Item key="4" icon={<RobotOutlined />}>Show automation</Menu.Item>
    </Menu>
  );
  
  return (
    <Col>
      {props.isMine 
      ? 
        <>
          <AddToCollectionButton sqid={key} /> { " "}

          <Dropdown.Button onClick={edit} overlay={menu}>
            <EditOutlined />Edit
          </Dropdown.Button>
        </>
      : 
        <>
          <Button
            style={{marginRight:"5px"}}
            onClick={e => {
              e.preventDefault();
              history.push( playgroundURL );
            }}
            htmlType="submit"
            href={playgroundURL} 
            icon={<CodeSandboxOutlined />}
          >
            Open in Playground
          </Button> 
          <SQButton
            style={{marginRight:"5px"}}
            placement="topRight"
            onClick={clone}
            loading={cloning}
            icon={<CopyOutlined />}
          >
            Clone
          </SQButton> 
        </>
      } 
    </Col>
  );
}

export {SequenceByParameter, SequenceByRecursion};
