import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './fonts/Sansation/Sansation_Regular.ttf';
import './fonts/Sansation/Sansation_Bold.ttf';
import './fonts/Sansation/Sansation_Bold_Italic.ttf';
import './fonts/Sansation/Sansation_Light_Italic.ttf';
import './fonts/Sansation/Sansation_Light.ttf';
import './fonts/Sansation/Sansation_Italic.ttf';

import './fonts/Poppins/Poppins-Black.ttf';
import './fonts/Poppins/Poppins-BlackItalic.ttf';
import './fonts/Poppins/Poppins-Bold.ttf';
import './fonts/Poppins/Poppins-BoldItalic.ttf';
import './fonts/Poppins/Poppins-ExtraBold.ttf';
import './fonts/Poppins/Poppins-ExtraBoldItalic.ttf';
//import './fonts/Poppins/Poppins-ExtraLight.ttf';
//import './fonts/Poppins/Poppins-ExtraLightItalic.ttf';
//import './fonts/Poppins/Poppins-Light.ttf';
//import './fonts/Poppins/Poppins-LightItalic.ttf';
import './fonts/Poppins/Poppins-Medium.ttf';
import './fonts/Poppins/Poppins-MediumItalic.ttf';
import './fonts/Poppins/Poppins-Regular.ttf';
import './fonts/Poppins/Poppins-Italic.ttf';

import './fonts/Poppins/Poppins-SemiBold.ttf';
import './fonts/Poppins/Poppins-SemiBoldItalic.ttf';
//import './fonts/Poppins/Poppins-Thin.ttf';
//import './fonts/Poppins/Poppins-ThinItalic.ttf';




import * as serviceWorker from './serviceWorker';

document.body.style.backgroundColor = "#F9F7F7";

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

