import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fade } from "react-reveal";

const FeaturesCantMiss = () => {
  return (
    <section className="section features-cant-miss">
      <div className="shapes-container overflow-clear">
        <div className="shape shape-circle shape-circle-1">
          <Fade bottom right>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-2">
          <Fade bottom left delay={300}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-3">
          <Fade bottom right delay={400}>
            <div />
          </Fade>
        </div>
        <div className="shape shape-circle shape-circle-4">
          <Fade bottom right delay={600}>
            <div />
          </Fade>
        </div>

        <div className="shape shape-triangle shape-animated">
          <div className="animation--rotating" />
        </div>
      </div>
      <Container>
        <Row className="gap-y">
          <Col md={{ size: 6, order: "last" }}>
            <div className="section-heading">
              <FontAwesomeIcon
                icon={["fas", "upload"]}
                className="fa-2x text-danger mb-3"
              />
              <h2 className="heading-line">Show, don't tell</h2>
              <h3 className="heading-line">Great for building utilities, documenting, or providing working examples.</h3>

            </div>

            <ul className="list-unstyled">
              <li className="media flex-column flex-md-row text-center text-md-left">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-lock pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Secrets</h5>
                  <p className="m-0">
                    If you are going to share a sequence, then you need to hide sensitive data, such as tokens and passwords. 
                    Mark values as secret and we use AWS Secrets Manager to store them and protect you.&nbsp;
                    <a href="https://blog.sequenceapi.com/secrets/">Learn more</a>
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-speaker pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Publish</h5>
                  <p className="m-0">
                    Sequences are default private. But you can publish generic sequences so that others can use them. 
                    For example, a multistep sequence that builds and formats an authentication header for AWS.&nbsp;
                    <a href="https://blog.sequenceapi.com/publishing-sequences-and-collections/">Learn more</a>                
                  </p>
                </div>
              </li>

              <li className="media flex-column flex-md-row text-center text-md-left mt-4">
                <i className="mx-auto mr-md-3 mx-auto mr-md-3 accent pe pe-7s-loop pe-3x" />
                <div className="media-body mt-3 mt-md-0">
                  <h5 className="bold mt-0 mb-1">Reuse</h5>
                  <p className="m-0">
                  Run, clone or embed published and personal sequences.&nbsp;
                  <a href="https://blog.sequenceapi.com/using-a-published-sequence/">Learn more</a>
                  </p>
                </div>
              </li>
            </ul>
          </Col>

          <Col md="6">
            <div style={{boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)"}}>
                <img width="100%" src="landingpage/collection.png" alt="..." />              
            </div>
            <div className="notch" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FeaturesCantMiss;
