import React, { useState } from 'react'

const PublishContext = React.createContext([{}, () => {}])

const PublishProvider = ({ children }) => {
  const [state, setState] = useState({})
  return (
    <PublishContext.Provider value={[state, setState]}>
      {children}
    </PublishContext.Provider>
  )
}

export {PublishContext, PublishProvider}