import React, {useState, useEffect, useContext } from "react";
import { Button, Spin, Typography, Row, Col, Tooltip } from "antd";
import { useHistory } from "react-router-dom";
import { updateSequence, fetchSequence, cloneSequence, getPublishedState } from "./Utils";
import { CodeSandboxOutlined } from '@ant-design/icons';
import { CredentialsContext } from "./CredentialsContext";
import { PublishButton, PublishTag, ShareField } from "./Publish";
import { PublishProvider, PublishContext} from "./PublishContext";

import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";

import { SQPage, Editor, useSignInStatus } from "./Components";
import log from 'loglevel';

function Edit(props) {
  return (
    <PublishProvider>
      <Edit2 />
    </PublishProvider>
  ); 
}

function Edit2() {
  //log.debug("Edit:" + JSON.stringify(props));
  const [data, setData] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [version, setVersion] = useState(-1);
  const [updated, setUpdated] = useState("");

  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("id");
  const mySub = useContext(CredentialsContext);
  const [invalidated, setInvalidated] = useState(false);
  const [publishState, setPublishState] = useContext(PublishContext); 
  const isSignedIn = useSignInStatus();

  const { Text } = Typography;
  log.info("Edit Render: " + JSON.stringify(publishState));


  useEffect(() => {
    log.info("WILL FETCH: ");
    setPublishState({template: null, isSaved: true, setAllowPublish: false, isPublished: false});
    fetchSequence(key).then(function(value) {

      log.debug("fetched: " + JSON.stringify(value.data));
      log.debug("graph: " + JSON.stringify(value.data.rTemplate.graph));

      var url = "/a/" + key;

      const inputs = value.data.vTemplate.dependencies.required;

      for (var index = 0; index < inputs.length; index++) {
        url = url + "/" + inputs[index];
      }

      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: inputs,
              secrets: value.data.vTemplate.dependencies.secrets,
              author: value.data.Author,
              automateURL: url,
              includesMocks: value.data.vTemplate.includesMocks
            });
      
      setPublishState(getPublishedState(value.data));
      setUpdated(value.data.Updated);
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      setLoading(false);
    });       
    
    return () => {
      // Clean up the subscription
    };
  }, [key, version]);

  useEffect(() => {

    if (invalidated) {
      log.debug('invalidating', publishState.publishedVersion);

      setPublishState({
                  ...publishState,
                  template: null, 
                  isSaved: false, 
                  setAllowPublish: false
                }); 

      setInvalidated(false);
    }

  }, []);

  function validated(template) {

    log.debug("validated" + JSON.stringify(publishState));

    setPublishState({
                    ...publishState,
                    template: template, 
                    isSaved: false, 
                    setAllowPublish: false,
                    });  
  }
/*
  async function reloadSequence() {
    
    fetchSequence(key).then(function(value) {

      log.debug("reloaded: " + JSON.stringify(value.data));

      var url = "/a/" + key;

      const inputs = value.data.vTemplate.dependencies.required;

      for (var index = 0; index < inputs.length; index++) {
        url = url + "/" + inputs[index];
      }

      setData({template: value.data.Template, 
              rTemplate: value.data.rTemplate,
              required: inputs,
              secrets: value.data.vTemplate.dependencies.secrets,
              author: value.data.Author,
              automateURL: url
            });
      
      //setPublishState(getPublishedState(value.data));
    })
    .catch(function(error) {
      console.error(error);
    })      
    .finally(function() { 
      //setLoading(false);
    });     
  }*/

  function save() {

    if (publishState.template !== null) {
      setIsSaving(true);
      updateSequence(key, publishState.template).then(function(value) {
        log.info("SAVE: " + JSON.stringify(value.data));
        //invalidated();
        setPublishState({...publishState,
                        template: null, 
                        isSaved: true, 
                        setAllowPublish: true, 
                        savedVersion: value.data.version});  

        //history.push( "/sequence?id=" + key );
        //reloadSequence();
        setVersion(value.data.version);
      })
      .catch(function(error) {
        if (error.response !== undefined)
          console.error(error.response);
        else
          console.error(error.message);
      })
      .finally(function() {
        setIsSaving(false);
      });
    }
  }

  function clone() {

    setIsSaving(true);

    cloneSequence(key).then(function(value) {
      //log.info("/sequence?id=" + value.data.sequence_id);
      history.push( "/sequence?id=" + value.data.sequence_id );
      //log.info("Required:" + JSON.stringify(value.data.vTemplate.dependencies.required))
    })
    .catch(function(error) {
      console.error(error.response);
    })
    .finally(function() {       
      setIsSaving(false);
    });
  }

  var extra;
  
  if (!loading) {

    if (mySub === data.author ) { 
      extra = 
      <span key="gggg">
 
        <Button onClick={save} disabled={publishState.template === null} loading={isSaving}>
            Save
        </Button>  
        {
          (publishState.isSaved) ? null :
          <Text type="secondary" style={{ marginLeft: "10px" }}>Unsaved changes</Text> 
        }
      </span>
    }
    else {
      extra = 
        <Tooltip title="Must be signed in">
          <Button onClick={clone} disabled={publishState.template === null || !isSignedIn} loading={isSaving}>
            Clone
          </Button>  
        </Tooltip>
    }     
  }

  return (
      <SQPage>
        <h1><CodeSandboxOutlined style={{marginRight:"20px", verticalAlign: "middle"}} />Edit</h1>
        <p>Edit the JSON, check and run the Sequence and then save it if you are happy. {"   "}<ShareField id={key}/></p>
        {
          (loading) 
          ? <Spin size="large" />
          : 
            <>
              <Row justify="end">
                <Col>
                  <PublishTag />
                  <PublishButton id={key}/>
                </Col>
              </Row>
              <br />
              <Editor extra={extra} 
                      sqid={key}
                      isSaved={publishState.isSaved}
                      onValidation={validated} 
                      onInvalidation={() => {setInvalidated(true)}} 
                      template={data.template}
                      rTemplate={data.rTemplate}
                      required={data.required}
                      secrets={data.secrets}
                      updated={updated}
                      includesMocks={data.includesMocks}
              />
              <Row justify="end">
                <Col>
                  <Button onClick={e => {
                        //e.preventDefault();
                        history.push(data.automateURL);
                      }} type="link">
                      Automate
                    </Button>
                  <Button onClick={e => {
                      //e.preventDefault();
                      history.push( "/sequence?id=" + key);
                    }} type="link">
                    Stop editing
                  </Button>
                </Col>
              </Row>
            </>
        }
      </SQPage>
  ); 
}
export default Edit;
