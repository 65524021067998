import React from "react";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import {subscribe} from "../../../Utils";
import {Button} from "antd";
import {LoginDrawer} from "../../../../Components.js";

function Subscribe (props) {
  return (
    <section className="section stay-tunned">
      <Container className="bring-to-front">
        <div className="shadow rounded p-5 bg-contrast overflow-hidden">
          <div className="section-heading text-center">
            <h2 className="heading-line"><FontAwesomeIcon icon={["fas", "smile-beam"]} className="fa-lg" />{"  "} Next Steps...</h2>
            <p className="lead text-alternate">
              Sign up now and get building and testing.               

            </p>
            <LoginDrawer  style={{marginRight: "10px"}} 
                key="l1" 
                buttonType="danger" 
                buttonText="Sign Up Free" 
                signUpSource={props.signUpSource + "/nextsteps"}
                auth='signUp' /> 
             <p className="lead text-alternate">
              Not quite ready to sign up? Learn more about SequenceAPI <a href="https://blog.sequenceapi.com/sequence-api-concepts-an-overview/" style={{color:"orange"}}>here</a>.              

            </p>               
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Subscribe;
