import React from 'react';
import { Hub } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import log from 'loglevel';

const SIGNIN = "SQ_sign_in";

export function addSignInListener(listener) {

  Hub.listen(SIGNIN, listener);
}    
export function removeSignInListener(listener) {

  Hub.remove(SIGNIN, listener);
} 

export function signOut(context, history) {
  try {
    Auth.signOut();
    context = undefined;
    history.push( "/");
  } catch (error) {
      log.info('error signing out: ', error);
  }
}

export const CredentialsContext = React.createContext(undefined);