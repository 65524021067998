import React from "react";
import { SQPage, CollectionCard, LoadingCard } from "./Components";
import { fetchMyCollections, createCollection } from "./Utils";
import { useEffect, useState } from "react";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import {Col,Row,Button,Form,Input,Alert} from "antd";
import { AppstoreOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import log from 'loglevel';

const { TextArea } = Input;


function MyCollections(props) {

  const [data, setData] = useState({author: "", loaded: false, collections: []});
  const [form] = Form.useForm();
  const history = useHistory();
  const [createError, setCreateError] = useState(null);


  log.info("MySequences:" + JSON.stringify(props));

  useEffect(() => {

    //log.info("fetch: " + props.id);

    fetchMyCollections().then(function(value) {

      log.info("fetched YYY: " + JSON.stringify(value.data));
      setData({author: "You", loaded: true, collections: value.data});
    })
    .catch(function(error) {
      console.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, []);

  const onFinish = (values) => {
    log.info("Success:", values);
    //Can directly call props here
    createCollection(values.name, values.description).then(function(value) {
      log.info("create returned " + JSON.stringify(value));

      fetchMyCollections();
      history.push( "/collection?id=" + value.data.id);
    })
    .catch(function(err) {
      console.error(err.response.data);
      setCreateError(err.response.data);

      //setError({visible: true, text: JSON.stringify(err.response.data)});
    })
    .finally(function() {
      //setIsSaving(false);
    });
  };

  return (
    <SQPage>

    <h1><AppstoreOutlined style={{marginRight:"20px", verticalAlign: "middle"}} />My Collections</h1>

      <div>
        <Row>

        {
          (data.loaded)
          ?
          data.collections.map((coll) => (
              
              <Col key={coll.id} >
                <CollectionCard id={coll.id} 
                                name={coll.name} 
                                author={data.author} 
                                description={coll.description} 
                                isPublished={coll.isPublished}/>
              </Col>
            ))   
          :
            <div>
              <Col><LoadingCard /></Col>
              <Col><LoadingCard /></Col>
              <Col><LoadingCard /></Col>
            </div>
        }
        </Row>  
        <Row style={{marginTop:"50px"}}>
          <Col span={6}>
          {
          (createError !== null) ?      
          <Alert message={createError} type="error" afterClose={() => setCreateError(null)} closable/> :
          null 
          }
          </Col>
        </Row>

        <Form
          layout="inline"
          form={form}
          style={{marginTop:"10px"}}
          onFinish={onFinish}
        >
          <Form.Item label="Name" name="name">
            <Input placeholder="A name for the collection" />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <TextArea rows={1} cols={40} autoSize={true} placeholder="A description of the collection"/>
          </Form.Item>
          <Form.Item >
            <Button type="primary" htmlType="submit" icon={<AppstoreAddOutlined />}>Create</Button>
          </Form.Item>
        </Form>

      </div>
    </SQPage>
  ); 
}

export default MyCollections;
