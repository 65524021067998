import React from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Input,
  Collapse,
  Popover,
  Tag,
  Table,
  Tabs,
  Steps,
  Spin,
  Typography,
  notification
} from "antd";

import "antd/dist/antd.less";
import {
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  WarningTwoTone,
  InfoCircleFilled,
  CaretRightOutlined
} from "@ant-design/icons";
import {Automate} from "./Automate";
import { Cache } from 'aws-amplify';

import "antd/dist/antd.css";
import "./App.css";
import { useState, useReducer, useEffect, useContext} from "react";
import { Hub } from "aws-amplify";
import {CredentialsContext} from "./CredentialsContext";

import {isLastEndpointInTemplate, isFirstEndpoint, getParents} from "./ParserUtils";
import { runFromID, runFromTemplate } from "./Utils";
import log from 'loglevel';

import JSONInput from "react-json-editor-ajrm";
//import locale from "react-json-editor-ajrm/locale/en";
const { TabPane } = Tabs;
const { Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Step } = Steps;
const { Title } = Typography;

const openNotificationWithIcon = (type, title, message) => {
  notification.close("runNotif");
  
  notification[type]({
    message: title,
    description: message,
    duration: 0,
    placement: 'bottomRight',
    key: "runNotif"
  });
};

const openRunNotification = (status, message, reason, output, setShowAutomation, isSaved) => {

  notification.close("runNotif");
  var description = [<p key="1">{reason}</p>];

  if (output !== undefined) {
    if (Object.keys(output).length) {

      description.push(<br key="2" />);

      description.push(<h4 key="3" >The following output was returned:</h4>);
      description.push(<JSONInput
                  key="4" 
                  theme="light_mitsuketa_tribute"
                  placeholder={output}
                  height="100%"
                  width="100%"
                  viewOnly={true}
                  confirmGood={false}
                />);
    }
  }

  appendAutomationPath(description, setShowAutomation, isSaved)

  notification[status]({
    message: message,
    description: description,
    duration: 0,
    placement: 'bottomRight',
    style: {
      background: "#FCFDFD",
    },
    key: "runNotif"
  });
};

function appendAutomationPath(parent, setShowAutomation, isSaved) {
  
  if (isSaved) {
    
    const automateLink = <Button
      type="link"
      key="55"
      htmlType="submit"
      onClick={e => {
        notification.close("runNotif");
        setShowAutomation(true);
      }}
      >
        View automation for these inputs
      </Button>

    parent.push(automateLink);

  }
  else {
    parent.push(<Text key="6" code>Save to view automation for these inputs</Text>);

  }
}

function Endpoints(props) {
  log.info("Building endpoint view, isSaved: " + JSON.stringify(props.template.graph));
  const [summary, setSummary] = useState({isRunning: false});
  const [showAutomation, setShowAutomation] = useState(false);

  const context = useContext(CredentialsContext);
  const [topic, setTopic] = useState(undefined);

  function runComplete (data, inputs) {

    var msg = "Successful";
    var success = "success";
    if (!data.successful) {
      msg = "Failed";
      success = "error";
    }

    log.info("DATA:" + JSON.stringify(data.runID));
    setSummary({isRunning: false, inputs: inputs});
    openRunNotification(success, msg, data.message, data.outputs, setShowAutomation, props.isSaved); 
  }

  function badEnd(data, inputs) {

    var msg = "Failed";
    var success = "error";

    log.info("DATA:" + JSON.stringify(data.runID));
    setSummary({isRunning: false, inputs: inputs});
    openRunNotification(success, msg, data.message, data.outputs, setShowAutomation, props.isSaved);
  }

  useEffect(() => {

    const processUpdate = (data) => {

      log.info("process update");
  
      if (data.type === 0) {}
      else if (data.type === 1) runComplete(data, summary.inputs);
      else if (data.type === 2) {}
      else if (data.type === 3) badEnd(data, summary.inputs);
    }

    const listener = async data => {
      log.info("Endpoints received msg: " + JSON.stringify(data.payload.data));
      processUpdate(data.payload.data);
    };
    if (topic !== undefined) {

      log.info("Endpoints adding listener: " + topic);
      Hub.listen(topic, listener);
    }

    return () => {
      // Clean up the subscription
      log.info("Endpoints might remove listener: " + topic);

      if (topic !== undefined) {
        log.info("Endpoints removing listener: " + topic);
        Hub.remove(topic, listener);
      }
    };
  }, [topic, badEnd, runComplete, summary]);

  function doRunFromID(inputs) {

    log.info("doRunFromID:       " + props.sqid);
    runFromID(props.sqid, inputs, context)
    .then(function(value) {
      log.debug("Run launched, runID: " + value.data.run_id);
      setTopic(value.data.run_id);
    })
    .catch(function(error) {
      log.info(error);
      setSummary({isRunning: false, inputs: summary.inputs});
      //openRunNotification(success, msg, data.message, data.outputs);
      openNotificationWithIcon('error', 'Run failed', error.response.data);
      log.info("ERROR:" + JSON.stringify(error.response));
    });
  }

  function doRunFromTemplate(inputs) {

    log.info("SECRETS:" + JSON.stringify(props.secrets));
    log.info("INPUTS:" + JSON.stringify(inputs));

    runFromTemplate(props.template, props.name, inputs, props.required, props.secrets, context)
    .then(function(value) {
      setTopic(value.data.run_id);

    })
    .catch(function(error) {
      setSummary({isRunning: false, inputs: summary.inputs
      });
      //openRunNotification(success, msg, data.message, data.outputs);
      openNotificationWithIcon('error', 'Run failed', error.response.data);
      log.info("ERROR:" + JSON.stringify(error.response));
    });
  }

  function runTests(inputs) {
    log.info("runTests:" + JSON.stringify(inputs));

    setSummary({isRunning: true, inputs: inputs});

    if (props.isSaved) {
      doRunFromID(inputs);
    }
    else {
      doRunFromTemplate(inputs);
    }
  }

  const onFinish = (values) => {
    //log.info("Success:", values);
    runTests(values);
    //Can directly call props here
  };
  
  return (
    <div style={{ marginTop: "30px"}}>

      {(showAutomation)
      ?
        <Automate inputs={summary.inputs} sqid={props.sqid} setShowAutomation={setShowAutomation} />
      :
        <Row>
          <Col span={24} >
            <Row>
              <Col>
                <TemplateView
                  isSaved={props.isSaved}
                  topic={topic}
                  nested="false"
                  template={props.template}
                  immediateChildren={props.template.graph}
                ></TemplateView>
              </Col>
            </Row>
              
            <Form onFinish={onFinish} style={{ marginTop: "50px" }}>
              {Object.entries(props.inputs).map(([key, value]) => {

                return (
                  <Row key={value} justify="end">
                    <Col span={24}>
                      <Form.Item
                        key={value}
                        label={value}
                        name={value}
                        rules={[{ required: true, message: 'Please provide a value for ' + value }]}
                      >
                        <TextArea rows={1} autoSize={true} />
                      </Form.Item>   
                    </Col>
                  </Row>
                      
                  );
                })}
              <Row >
                <Col>
                {(summary.isRunning) 
                ?
                  <Button shape="round" type="run" disabled={true} htmlType="submit" icon={<CaretRightOutlined />} loading>
                    Run Sequence
                  </Button>         
                :
                  <Button shape="round" type="run" htmlType="submit" >
                    <CaretRightOutlined style={{paddingBottom: "40px"}} />
                    Run Sequence
                  </Button>   
                }
                </Col>
              </Row>
            </Form>
          </Col>
        
        </Row>
      }
    </div>
  );
}

function CallResult(props) {
  var status = "";
  var color = "#48C52C";
  var popup = "";

  if (props.endpoint !== undefined) {
    if (props.endpoint[1] > -1) 
      status = props.endpoint[1];

    if (status.length === 0) {
      return <div></div>;
    }

    if (status > 299) {
      color = "#E55C2D";
    }

    const content = <div>
        <RunSummary
          result={props.endpoint[2]}
        />
      </div>

    popup = (
      <Popover placement="left" title="Endpoint summary" content={content} trigger="hover">
        <InfoCircleFilled fill="red" style={{ marginLeft: "16px", color: "orange", fontSize: '16px' }} />
      </Popover>
    );

    return (
      <div>
        <Tag color={color}>{status}</Tag>
        {popup}
      </div>
    );
  }
  return <div></div>;
}

function RunSummary(props) {

  log.info(JSON.stringify(props.result));

  const sumData = [
    {
      key: 'Result',
      value: props.result.message,
    },
    {
      key: 'Inputs',
      value: props.result.message,
    },
    {
      key: 'Outputs',
      value: props.result.message,
    },
    {
      key: 'Is Mock?',
      value: props.result.message,
    }
  ];
  
  const sumCols = [
    {
      dataIndex: 'key',
      key: 'key',
      width:150,
      render: (text) => {
        return <Title level={5}><Text type="success">{text}</Text></Title>
      }
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (text, record, index) => {
        if (index === 1) {
          return <JSONInput
            viewOnly={true}
            theme="light_mitsuketa_tribute"
            placeholder={props.result.inputs}
            confirmGood={false}
            height="100%"
            width="100%"
          />
        }
        else if (index === 2) {

          if (props.result.endpointOutput === undefined) 
            return "";
          if (Object.keys(props.result.endpointOutput).length === 0) 
            return "No outputs defined";
          return <JSONInput
            viewOnly={true}
            theme="light_mitsuketa_tribute"
            placeholder={props.result.endpointOutput}
            confirmGood={false}
            height="100%"
            width="100%"
          />
        }
        else if (index === 3){
          if (props.result.isMock) return <Tag color="warning">Yes</Tag>
          return "No";
        }
        else {
          return text
        }
      }
    }
  ];

  const invData = [
    {
      key: 'URL',
      value: props.result.invocation.url,
    },
    {
      key: 'Method',
      value: props.result.invocation.method,
    },
    {
      key: 'Headers',
      value: props.result.invocation.headers,
    },
    {
      key: 'Data',
      value: props.result.invocation.data,
    }
  ];
  
  const invCols = [
    {
      dataIndex: 'key',
      key: 'key',
      width:150,
      render: (text) => {
        return <Title level={5}><Text type="success">{text}</Text></Title>
      }
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (text, record, index) => {
        if (index === 0) {
          return <a>{text}</a>
        }
        else if (index === 1) {
          log.info("TEXT:" + text);
          if (text.toUpperCase() === "POST") return <Tag color="#87d068">POST</Tag>;
          else if (text.toUpperCase() === "GET") return <Tag color="#108ee9">GET</Tag>;
          else return <Tag color="#f50">{text.toUpperCase()}</Tag>;

        }
        else if (index === 2 || index === 3) {

          var json = (index === 2 ? props.result.invocation.headers : props.result.invocation.data);
          return <JSONInput
            viewOnly={true}
            theme="light_mitsuketa_tribute"
            placeholder={json}
            height="100%"
            confirmGood={false}
            width="100%"
          />
        }
        else {
          return text;
        }
      }
    }
  ];

  const failData = [
    {
      key: 'Fail Reason',
      value: props.result.failReason,
    },
    {
      key: 'Attempts',
      value: props.result.tries,
    },
    {
      key: 'Retry on fail?',
      value: (props.result.willRetry) ? "Yes" : "No",
    },
    {
      key: 'Continue',
      value: (props.result.continue === undefined) ? "" : (props.result.continue) ? "Yes" : "No",
    }
  ];
  
  const failCols = [
    {
      dataIndex: 'key',
      key: 'key',
      width:150,
      render: (text) => {
        return <Title level={5}><Text type="success">{text}</Text></Title>
      }
    },
    {
      dataIndex: 'value',
      key: 'value'
    }
  ];


  const respData = [
    {
      key: 'Result',
      value: props.result.message,
    },
    {
      key: 'Status goal',
      value: "Expected " + props.result.status.expected + ", received " + props.result.status.received,
    },
    {
      key: 'Output goal',
      value: "",
    },
    {
      key: 'Response',
      value: props.result.output.raw,
    }
  ];
  
  const respCols = [
    {
      dataIndex: 'key',
      key: 'key',
      width:150,
      render: (text) => {
        return <Title level={5}><Text type="success">{text}</Text></Title>
      }
    },
    {
      dataIndex: 'value',
      key: 'value',
      render: (text, record, index) => {
        if (index === 2) {

          log.info("RESP: " + JSON.stringify(props.result.output));

          if (Object.keys(props.result.output.expected).length === 0) 
            return "Not set";
          return (              
            <div>
                <Row><Col>Expected output:</Col></Row>

                <Row><Col>
                  <JSONvsTextRenderer text={props.result.output.expected} /> 
                </Col></Row>
                <Row><Col>Received output:</Col></Row>
                <Row><Col>
                  <JSONvsTextRenderer text={props.result.output.received} />
                </Col></Row>
            </div>)
        }
        else if (index === 3) {

          return (
            <JSONvsTextRenderer text={props.result.output.raw} />
          );
        }
        else {
          return text;
        }
      }
    }
  ];

  return (
    <div style={{ width: "800px", maxWidth: "800px", maxHeight: "500px" , height: "500px"}}>
      <Tabs defaultActiveKey="0">
      <TabPane tab="Summary" key="0">
          <Table dataSource={sumData} showHeader={false} columns={sumCols} pagination={false} scroll={{ x: 400, y: 400 }} size="small" />
        </TabPane>
        <TabPane tab="Invocation" key="1" >
          <Table dataSource={invData} showHeader={false} columns={invCols} pagination={false} scroll={{ x: 400, y: 400 }} size="small" />
        </TabPane>
        <TabPane tab="On failure" key="2">
          <Table dataSource={failData} showHeader={false} columns={failCols} pagination={false} scroll={{ x: 400, y: 400 }} size="small" />       
        </TabPane>
        <TabPane tab="Response" key="3">
          <Table dataSource={respData} showHeader={false} columns={respCols} pagination={false} scroll={{ x: 400, y: 400 }} size="small" />   
        </TabPane>

      </Tabs>
    </div>
  )
}

function JSONvsTextRenderer(props) {

  const [isJSON, setIsJSON] = useState(0);
  //log.info("TEXT1: " + props.text);
  //log.info(typeof props.text);
  useEffect(() => {

    //log.info("TEXT: " + JSON.stringify(props.text));
    if (typeof props.text === 'object') setIsJSON(2);
    else setIsJSON(1);
    /*
    try {
        obj = JSON.parse(JSON.stringify(props.text));
        setIsJSON(2);
        log.info("TEXT a: " + JSON.stringify(obj));

    } catch (e) {
      setIsJSON(1);
      log.info("TEXT b: ");
    }*/

    return () => {

    };
  }, [props.text]);


  if (isJSON === 2) {

    return (
      <JSONInput
        viewOnly={true}
        theme="light_mitsuketa_tribute"
        placeholder={props.text}
        confirmGood={false}
        height="100%"
        width="100%"
      />
    )
  }
  else if (isJSON === 1) {
    //log.info("TEXT14: " + props.text);
    return <Text code>{props.text}</Text>;
  }
  //log.info("TEXT15: " + props.text);

  return "";
}

function getIcon(id, data) {

  const endpointData = data[id];

  if (endpointData !== undefined) return endpointData[0];

  return <ClockCircleTwoTone twoToneColor="#10D0F7" />;
}

export function TemplateView(props) {
  const [state, dispatch] = useReducer(reducer, {});

  log.info("TemplateView, graph init:" + JSON.stringify(props.immediateChildren));

  //log.info("TemplateView, setting topic " + props.topic);

  useEffect(() => {

    log.info("TemplateView, resetting topic " + props.topic);

    dispatch({
      type: "RESET"
    });

    const processUpdate = (data) => {

      log.info("TemplateView, process update: " + JSON.stringify(data));
  
      if (data.type === 0) {
        var isSuccessful = data.status.successful;
  
        if (isSuccessful) {
          if (data.output.successful !== undefined) {
      
            isSuccessful = data.output.successful;
          }
        }
        
        dispatch({
          status: data.status.received,
          id: data.endpointID,
          data: data,
          successful: isSuccessful,
          type: "RESPONSE",
          willRetry: data.willRetry
        });
      }
      else if (data.type === 1) {}
      else if (data.type === 2) {
        //log.debug("MESSAGE TYPE 2");
        dispatch({
          id: data.endpointID,
          attempts: data.attempt,
          type: "LOAD"
        });
      }
      else if (data.type === 3) 
        dispatch({
          status:-1,
          id: data.endpointID,
          data: data,
          successful: false,
          type: "RESPONSE",
          willRetry: false
        });   
    }

    const listener = async data => {
      log.info("TemplateView listener, received msg: " + JSON.stringify(data.payload.data));
      processUpdate(data.payload.data);
    };
    if (props.topic !== undefined) {

      log.info("TemplateView, adding listener: " + props.topic);

      Hub.listen(props.topic, listener);
    }

    try {
      log.info("Will check missed messages");

      const missed = Cache.getItem(props.topic);
        
      //log.info(JSON.stringify(missed));
      //log.info("****************");
      if (missed !== null) {
        log.info("MISSED: " + JSON.stringify(missed));
        missed.forEach(update => {
          log.info("Process missed:" + JSON.stringify(update));
          processUpdate(update);
        });
      }
    }
    catch (error){
      console.error(error);
    }

    return () => {
      // Clean up the subscription
      if (props.topic !== undefined) {
        log.info("TemplateView, removing listener: " + props.topic);

        Hub.remove(props.topic, listener);
      }
    };
  }, [props.topic]);

  function reducer(state, action) {

    log.info("action:" + JSON.stringify(action));

    const type = action.type;

    if (type === "RESET") {
      return {};
    }

    const id = action.id;
    //log.info("id: " + id);

    var parents ;
    try {
      parents = getParents(props.template, id);
    }
    catch (erro) {
      log.info("PARENTS: " + JSON.stringify(props));

    }
    
    //log.info("parents: " + JSON.stringify(parents));

    var icon = <CheckCircleTwoTone twoToneColor="#48C52C" />;

    if (type === "RESPONSE") {
      //const data = JSON.stringify(action.data);
      log.info("isResponse");

      if (!action.willRetry) {
        if (action.successful) {
          log.info("isSuccessful");
  
          var isLast = isLastEndpointInTemplate(props.template, id);
  
          if (isLast) {
            log.info("isLast");
            setParentsIcon(parents, icon, state);
          }
        } else {
          log.info("not Last");
  
          icon = <WarningTwoTone twoToneColor="#E55C2D" />;
          setParentsIcon(parents, icon, state);
        }
        state[id] = [icon, action.status, action.data];
      }

    } else if (type === "LOAD") {
      log.info("isLoad");


      if (state[id] === undefined) {

        icon = <Spin key="sfrefrf"></Spin>;
        state[id] = [icon, "", {}];
  
        var isFirst = isFirstEndpoint(props.template, id);
        if (isFirst) {
          log.info("isFirst");
          setParentsIcon(parents, icon, state);
        }        
      }     
    }

    return { ...state };
  }

  function setParentsIcon(parents, icon, state) {

    parents.forEach(parentID => state[parentID] = [icon, "", {}]);
  }

  var template = props.template;
  var graph = props.immediateChildren;

  //var graph = template.graph;
  log.info("TemplateView, graph:" + JSON.stringify(graph));
  var newChildren = [];

  for (var index = 0; index < graph.length; index++) {

    var child = graph[index];
    log.info("TemplateView, child:" + JSON.stringify(child));

    var name;

    if (typeof child === "object") {

      var icon = getIcon(child.id, state);

      var id = child.id;
      name = template.endpoints[id].name;
      log.info(JSON.stringify("Endpoint:" + name));

      newChildren.push(
        <Step
          key={id}
          icon={icon}
          description={
            <TemplateView
              key={id}
              nested="true"
              template={template}
              immediateChildren={child.children}
              title={name}
              status={state}
            />
          }
        ></Step>
      );
    }
    else {
      var icon2 = getIcon(child, state);

      name = template.endpoints[child].name;
      if (template.endpoints[child].mock) name = <>{name}&nbsp;&nbsp;<Tag color="warning">Mock</Tag></>
      
      log.info("name=" + JSON.stringify(template.endpoints[child]));
      newChildren.push(
        <Step
          title={name}
          key={child}
          icon={icon2}
          description={<CallResult endpoint={state[child]} />}
        ></Step>
      );   
    }
  }

  var steps = (
    <Steps size="small" direction="vertical" current="-1">
      {newChildren}
    </Steps>
  );

  if (props.nested === "false") {
    return <div>{steps}</div>;
  }

  return (

    <div>
      <Collapse ghost expandIconPosition="right">
        <Panel
          header={<Label title={props.title} />}
          className="custom"
          style={{ padding: "0px", margin: "0px", color: "red" }}
        >
          {steps}
        </Panel>
      </Collapse>
    </div>

  );
}

function Label(props) {
  return <p style={{ color: "grey" }}>{props.title}</p>;
}

export default Endpoints;
