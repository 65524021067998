import React from 'react';
import { Hub } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import AWSMqttClient from 'aws-mqtt'
import log from 'loglevel';
import {  createUID } from "./Utils";
import AWS from 'aws-sdk/global'

const hubListener = async data => {
  const { payload } = data;
  log.info("Auth event", JSON.stringify(payload));

  if (payload.event === "signIn") {
    log.info("SIGNED IN ");
    //setSub(payload.data.username);
    //fetchMyCollections();
    initListener();
  }
  else if (payload.event === "signOut") {
    //setSub(undefined);
  }
}

log.info("SUBSCRIBING HUB");
Hub.listen("auth", hubListener);

//Two entry points:
//1. load
//2. sign in


// get AWS credentials
// get user topic
//if existing listener not null unsubscribe
// createListener  
// subscribe

// also handle sign out

var listener;
var topic;
var disconnections = 0;


const region = process.env.REACT_APP_REGION;
const endpoint = process.env.REACT_APP_ENDPOINT;
const idPoolID = process.env.REACT_APP_ID_POOL_ID;
log.info("endpoint: " + endpoint);

initListener();

async function unsubscribe() {

  disconnections = 0;
  if (listener !== undefined) {
    log.info("unsubscribing ");
    listener.unsubscribe("/" + topic); 
  }
}

async function initListener() {

  try {
    await unsubscribe();
    const credentials = await getAWSCredentials();
    topic = await getIdentityForTopic(credentials);
    listener = createListener(credentials);
    log.info("subscribing on " + topic);
    listener.subscribe("/" + topic);
  }
  catch (error) {
    log.error(error);
  }
}

async function getAWSCredentials() {

  AWS.config.region = region;

  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: idPoolID
  });

  return AWS.config.credentials.getPromise()
    .then(function() {
      return AWS.config.credentials;
    });
}

function getIdentityForTopic(credentials) {

  return Auth.currentAuthenticatedUser()
  .then(user => {
    return user.username;
  })
  .catch(function(error) {
    log.info("auth error:" + error);
    return credentials._identityId
  })
}

function createListener(credentials) {
  //log.error(new Error());
  log.info("CONFIGURING AMPLIFY,region :" + region);
  
  const _listener =  new AWSMqttClient({
    region: AWS.config.region,
    credentials: credentials,
    endpoint: endpoint, // NOTE: get this value with `aws iot describe-endpoint`
    expires: 600, // Sign url with expiration of 600 seconds
    clientId: 'mqtt-client-' + createUID(), // clientId to register with MQTT broker. Need to be unique per client
    will: {
        topic: 'WillMsg',
        payload: 'Connection Closed abnormally..!',
        qos: 0,
        retain: false
    } 
  });

  _listener.on('message', (topic, data) => {
    data = JSON.parse(data);
    log.info("Received msg, topic: " + topic + " data: " + JSON.stringify(data));

    Hub.dispatch(
      data.message.runID , 
      { 
          event: 'runUpdate', 
          data: data.message, 
          message:'' 
      }
    );

    if (data.message.mode === "S") {
      Hub.dispatch(
        "runUpdate" , 
        { 
            event: 'runUpdate', 
            data: data.message, 
            message:'' 
        }
      );
    }
  });

  _listener.on('close', () => {
    var date = new Date();
    log.info("CONNECTION CLOSED: " + date.toLocaleTimeString());
    disconnections++;

    if (disconnections > 4) {
      initListener();
    }
    else {
      setTimeout(()=> {
        disconnections--
      }, 2000);
    }
  });
  _listener.on('offline', () => {
    // ...
    var date = new Date();
    log.info("CONNECTION OFFLINE: " + date.toLocaleTimeString());
    //initListener();
  });
  _listener.on('connect', () => {
    var date = new Date();
    log.info("CONNECTED: " + date.toLocaleTimeString());
  });

  return _listener;
}


export const WebSocketContext = React.createContext(undefined);