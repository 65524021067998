import React, { useEffect, useState, useContext } from "react";

import "./App.css";
import "./index.css";

import { SQPage, SQHeader, AsyncCollectionView } from "./Components";
import { CredentialsContext } from "./CredentialsContext";
import { WebSocketContext } from "./WebSocketContext";

import {  fetchMyCollections } from "./Utils";

import "antd/dist/antd.less";
import "antd/dist/antd.css";

import {CollectionByParameter, CollectionByRecursion} from "./Collection";
import {SequenceByParameter, SequenceByRecursion} from "./Sequence";
import MySequences from "./MySequences";
import MySecrets from "./MySecrets";
import MyCollections from "./MyCollections";
import {PlaygroundByRecursion, PlaygroundByParameter} from "./Playground";
import landingPages from "./landingpages/config";
import {AutomateByParameter} from "./Automate";
import  Index from "./landingpages/shared/views/Index.jsx";

import NewSequence from "./NewSequence";
import Runs from "./Runs";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Edit from "./Edit";
import { Amplify, Hub } from "aws-amplify";
import { Auth } from "@aws-amplify/auth";
import log from 'loglevel';

try {
  const level = process.env.REACT_APP_LOG_LEVEL;
  console.log("Log level set to " + level);
  log.setLevel(parseInt(level));
}
catch (error) {
  console.log("LOG LEVEL COULD NOT BE READ");
}

const region = process.env.REACT_APP_REGION;
const idPoolID = process.env.REACT_APP_ID_POOL_ID;
const poolID = process.env.REACT_APP_USER_POOL_ID;
const clientID = process.env.REACT_APP_CLIENT_ID;

log.info("region: " + region);
log.info("idPoolID: " + idPoolID);
log.info("poolID: " + poolID);
log.info("clientID: " + clientID);



function App() {

  const [sub, setSub] = useState(undefined);
  const [identityInit, setIdentityInit] = useState(false);
  useContext(WebSocketContext);

  useEffect(() => {

    log.info("CONFIGURING AMPLIFY,region (App):" + region);
    log.info("CONFIGURING credentials");
    
    Amplify.configure({
      Auth: {
        region: region,
        userPoolId:  poolID,
        userPoolWebClientId: clientID,
        mandatorySignIn: true
      }
    });

    const hubListener = async data => {
      const { payload } = data;
      log.info("Auth event (app)", JSON.stringify(payload));
    
      if (payload.event === "signIn") {
        log.info("SIGNED IN ");
        setSub(payload.data.username);
        fetchMyCollections();
      }
      else if (payload.event === "signOut") {
        setSub(undefined);
      }
    }
    Hub.listen("auth", hubListener);

    Auth.currentAuthenticatedUser()
    .then(user => {
      log.info("found authenticated user (app): " + user.username);
      setSub(user.username);
      fetchMyCollections();
    })
    .catch(function(error) {
      log.info("auth error:" + error);
    })
    .finally(function() { 
      setIdentityInit(true);
    }); 

    return (hubListener) => {
      Hub.remove("auth", hubListener);
    };
  }, );


  return (
    <div >  
      <TopLevelErrorBoundary>
        <CredentialsContext.Provider value={sub}>
          <Router basename="/">
            <Switch>
              <Route exact path="/"><LandingPage identityInit={identityInit} landingPage={landingPages.default}/></Route>
              <Route path="/sequence" component={SequenceByParameter} />
              <Route path="/s/:id" component={SequenceByRecursion} />
              <Route path="/playground" component={PlaygroundByParameter} />
              <Route path="/p/:id" component={PlaygroundByRecursion} />
              <Route path="/collection" component={CollectionByParameter} />
              <Route path="/c/:id" component={CollectionByRecursion} />
              <Route path="/a/:id" component={AutomateByParameter} />
              <Route path="/mysequences" component={MySequences} />
              <Route path="/mysecrets" component={MySecrets} />
              <Route path="/mycollections" component={MyCollections} />
              <Route path="/newsequence" component={NewSequence} />
              <Route path="/automate" component={AutomateByParameter} />
              <Route path="/runs" component={Runs} />
              <Route path="/edit" component={Edit} />
              <Route path="/empty" component={Empty} />

              {landingPages.extra.map(page => (
                  <Route key={page.url} path={page.url}><LandingPage identityInit={identityInit} landingPage={page} /></Route>          
                )
              )} 

            </Switch>
          </Router>  
        </CredentialsContext.Provider>
      </TopLevelErrorBoundary>
    </div>
  );
}

function Empty (){
  return (
    <>
    </>
  );
}

//landingPages.default
function LandingPage (props) {

  const context = useContext(CredentialsContext);
  const id = process.env.REACT_APP_TUTORIALS;

  return (
      <>
        {
          props.identityInit 
          ?
            (context === undefined) 
              ?      
                <>
                  <SQHeader showBack={false} signUpSource={props.landingPage.url} />
                  <Index messages={props.landingPage} signUpSource={props.landingPage.url}/>
                </>
              :
                <SQPage showBack={false}>

                  <AsyncCollectionView id={id} />
                </SQPage>
          :
          null
        }
      </>
  );
}

export class TopLevelErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidMount() {
    window.addEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  componentWillUnmount() {
    window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  }

  onUnhandledRejection = (event) => {
    event.promise.catch((error) => {
      this.setState(TopLevelErrorBoundary.getDerivedStateFromError(error));
    });
  };

  componentDidCatch(error, errorInfo) {
    console.log("Unexpected error occurred!", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
                <>
                  <SQPage>
                    <h1>
                      Something went wrong. Oops. 
                    </h1>
                    <p>
                      Please reload the page.
                    </p>
                  </SQPage>
                </>        
        </div>
      );
    }

    return this.props.children;
  }
}

export default App;
