import React from "react";
import { Row, Col} from "reactstrap";
import { setTwoToneColor } from '@ant-design/icons';
import '@aws-amplify/ui-react/styles.css';


function Heading (props) {
  setTwoToneColor('rgb(250 250 250 / .7)');
  var key = 0;
  
  return (
    <div style={{paddingTop:"20px", paddingBottom:"80px", }} className="header alter1-header text-contrast">
        <Row>
          <Col md="6">
            <h1 className="text-contrast display-5 display-md-4">
              <span className="bold">{props.message.h1}</span>
            </h1>
            <p className="lead bold">{props.message.h2}</p>

            {props.message.items.map(item => (
              <p key={key++}>
                {item}
              </p>            
              )
            )} 
          </Col>
          <Col md="6">

            <div style={{position: 'relative', paddingBottom: '62.5%', height: '0'}}>
              <iframe src="https://www.loom.com/embed/90868718bc8d4c73bfc8fff8211ded7c" 
                      title="Overview of Sequence API"
                      frameBorder="0" 
                      webkitallowfullscreen="true" 
                      mozallowfullscreen="true"
                      allowFullScreen 
                      style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}>
              </iframe>
            </div>
          </Col>
        </Row>
    </div>
  );
};


export default Heading;
