import React from "react";
import { SQPage, ExpandableDrawer } from "./Components";
import { TemplateView } from "./Run";
import dayjs from 'dayjs';
import { useEffect, useState, useCallback } from "react";
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import {Table, Tag, Button, Spin, Col, Row, Typography} from "antd";
import { HistoryOutlined } from '@ant-design/icons';
import { Hub } from "aws-amplify";
import { fetchSequence, fetchSequenceVersion, fetchMyRuns, fetchRunHistory } from "./Utils";
import { Cache } from 'aws-amplify';
import log from 'loglevel';

const { Title } = Typography;


const isToday = (someDate) => {
  const today = new Date();
  return someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
}

function Runs() {

  const [runs, setRuns] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedRun, setSelectedRun] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: 'Run ID',
      dataIndex: 'key',
      width: 100,
      key: 'key',
    },
    {
      title: 'Sequence',
      dataIndex: 'sqid',
      width: 120,
      key: 'key',
      render:  (text, record, index) => {
          
        const url = "/sequence?id=" + text;
        if (record.isHistoric)
          return <div><a href={url}>{text}</a>  (v{record.templateVersion})</div>;
          return <a href={url}>{text}</a>; 
        //log.info("record:" + JSON.stringify(record.date));
        //return "foo";
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      key: 'name',
    },
    {
      title: 'Started at',
      dataIndex: 'date',
      width: 150,
      key: 'date',
      render:  (text, record, index) => {

        var date = new Date(record.date);

        const parsedDate = dayjs(record.date);
        if (isToday(date)) return parsedDate.format('HH:mm');
        return parsedDate.format('MMM D');
        //log.info("record:" + JSON.stringify(record.date));
        //return "foo";
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 150,
      key: 'status',
      render:  (text, record, index) => {
        if (text === "Started") return <Tag key="1" color={"blue"}>Started</Tag>;
        if (text === "Failed") return <Tag key="2" color={"red"}>Failed</Tag>;
        if (text === "Successful") return <Tag key="3" color={"green"}>Successful</Tag>;
  
      },
    },
    {
      title: '',
      dataIndex: 'msg',
      key: 'msg',
    },
    {
      title: '',
      dataIndex: 'rid',
      key: 'action',
      render:  (text, record, index) => {
        return <Button key="4" onClick={e => {
          log.info(JSON.stringify(record));
          openRun(record.sqid, record.key, record.isHistoric, record.templateVersion);
        }}>
          View
        </Button>;
  
      },
    }
  ];

  const updateRow = useCallback((runID, status, msg) => {
      log.info("updateValue: " + runID);
      var newRuns = [...runs];
      newRuns.forEach(run => {
        //log.info("checking: " + runID);
  
        if (run.key === runID) {
          //log.info("updating: " + runID);
  
          run.status = status;
          run.msg = msg;
          setRuns(newRuns);
          return;
        }
        
      });
    },
    [runs],
  );

  function addRecord(newRuns, runID, sqid, date, name, status, msg, isHistoric, templateVersion) {
    // Standard case
    Cache.setItem(runID, []);

    const newRecord = {key: runID, 
      status: status, 
      msg: msg, 
      sqid:sqid, 
      date: date,
      name: name,
      index: Date.parse(date),
      isHistoric: isHistoric,
      templateVersion: templateVersion
    };

    var isInserted = false;

    for (var arrayIndex = 0; arrayIndex < newRuns.length; arrayIndex++) {
      
      if (newRuns[arrayIndex].index < newRecord.index) {

        newRuns.splice(arrayIndex, 0, newRecord);
        isInserted = true;
        break;
      }    
    }

    if (!isInserted) newRuns.push(newRecord);
  }

  useEffect(() => {

    const listener = async data => {
      //log.info(JSON.stringify(data.payload.data));

      data = data.payload.data;
      //log.info("process update: " + JSON.stringify(data) );

      if (data.type === 0) {
        const run = Cache.getItem(data.runID);

        if (run !== undefined && run != null) {
          // not sure why need this; i think its events come in that started before we loaded page
          //log.info("run: " + JSON.stringify(run) );

          var newRun = [...run];
          newRun.push(data);
          Cache.setItem(data.runID, newRun); 
        }
      }
      else if (data.type === 1) {
        // run ended
        if (data.successful) updateRow(data.runID, "Successful", data.message);
        else updateRow(data.runID, "Failed", data.message);
      
        /*
        const run = Cache.getItem(data.runID);
        log.info("****************");
        log.info(JSON.stringify(run));
        log.info("****************");*/

      }
      else if (data.type === 2) {
        // about to process another endpoint
      }
      else if (data.type === 3) {
        // could not invoke endpoint
        updateRow(data.runID, "Failed", data.message);
      }
      else if (data.type === 5) {
        log.info("process update (type 5): " + JSON.stringify(data));
        var newRuns = [...runs];
        addRecord(newRuns, data.runID, data.summary.sequence_id, data.summary.date, data.summary.name, "Started", '', false, -1);
        setRuns(newRuns);
      }
    };
    log.info("Runs subscribe");
    Hub.listen("runUpdate", listener);

    return () => {
      // Clean up the subscription
      log.info("Runs unsubscribe");
      Hub.remove("runUpdate", listener);
    };
  }, [runs, updateRow]);

  useEffect(() => {


    return () => {
      // Clean up the subscription
      log.info("cleaning");
      Cache.clear();
    };
  }, []);

  function openRun(sqid, rid, isHistoric, templateVersion){
    setDrawerVisible(true);
    log.info("IDs=" + sqid + " " + rid);
    setSelectedRun({sqid: sqid, rid: rid, isHistoric: isHistoric, templateVersion: templateVersion});
  }

  const onClose = () => {
    setDrawerVisible(false);
  };

  function fetchOlderRuns() {

    var date;

    if (runs.length > 0) date = runs[runs.length-1].date;
    log.info("Fetching older: " +  date);
    setLoading(true);
    fetchMyRuns(date).then(function(value) {

      log.info("fetched: " + JSON.stringify(value));
      var newRuns = [...runs];

      for (var index = 0; index < value.data.length; index++){

        var run = value.data[index];
        var start = run.Start;
        var end = run.End;
        var templateVersion = run.TemplateVersion;
        var msg = '';
        var status = "Started";

        if (end !== undefined) {
          msg = end.message;
          if (end.successful) status = "Successful";
          else status = "Failed";
        }
        addRecord(newRuns, start.runID, start.summary.sequence_id, start.summary.date, start.summary.name, status, msg, true, templateVersion);

      }
      setRuns(newRuns);
    })
    .catch(function(error) {
      console.error(error);
    })  
    .finally(function() {
      setLoading(false);
    });
  }

  return (
    <SQPage>

      <h1><HistoryOutlined style={{marginRight:"20px", verticalAlign: "middle"}} />Historical and live runs of Sequences</h1>
        <div>

          <Table columns={columns} dataSource={runs} />

        </div>
        <div>
          <Row justify="center"><Col><Button type="link" onClick={fetchOlderRuns}>Fetch older runs</Button></Col></Row>
          <Row justify="center"><Col>{loading ? <Spin size="large" /> : null}</Col></Row>
          
        </div>
      <ExpandableDrawer
          title="Run"
          visible={drawerVisible}
          onClose={onClose}
        >

        {(selectedRun !== undefined)
        ? 
          <TemplateViewWrapper sqid={selectedRun.sqid} rid={selectedRun.rid} isHistoric={selectedRun.isHistoric} templateVersion={selectedRun.templateVersion}/>
        :
          <div>Empty</div>
        }
      </ExpandableDrawer>
    </SQPage>
  ); 
}

function TemplateViewWrapper(props) {
  const [data, setData] = useState({template: {}, isValid: true, loaded: false, name: "", description: "", required: [], rTemplate: {endpoints:[], graph:[]}});

  useEffect(() => {

    function processResponse(value) {

      log.info("fetched: " + JSON.stringify(value));
      const orderedTemplate = {};
      orderedTemplate.name = value.data.Template.name;
      orderedTemplate.description = value.data.Template.description;
      orderedTemplate.dictionary = value.data.Template.dictionary;
      orderedTemplate.outputs = value.data.Template.outputs;
      orderedTemplate.endpoints = value.data.Template.endpoints;

      setData({name: value.data.vTemplate.name, 
              description: value.data.vTemplate.description, 
              required: value.data.vTemplate.dependencies.required,
              rTemplate: value.data.rTemplate,
              template: orderedTemplate,
              loaded: true,
              isValid: true});

      if (props.isHistoric) {
        fetchHistory();
      }
    }

    if (props.isHistoric) {
      log.info("Version=" + props.templateVersion);
      fetchSequenceVersion(props.sqid, props.templateVersion).then(function(value) {
        processResponse(value);
      })
      .catch(function(error) {
        console.error(error);
      })  
    }
    else {
      fetchSequence(props.sqid).then(function(value) {
        processResponse(value);
      })
      .catch(function(error) {
        console.error(error);
      })  
    }

    function fetchHistory() {
      log.info("fetching run history");

      fetchRunHistory(props.rid).then(function(value) {

        log.info("fetched history: " + JSON.stringify(value.data.events.length));

        if (value.data.events !== undefined) {

          value.data.events.forEach(event => {
            var ev = JSON.parse(event);
            log.info("checking: " + JSON.stringify(ev.endpointID));
      
              
            Hub.dispatch(
              ev.runID , 
              { 
                  event: 'runUpdate', 
                  data: ev, 
                  message:'' 
              }
            );      
            
          });
        }

      })
      .catch(function(error) {
        console.error(error);
      })  
    }
  }, [props.sqid, props.rid, props.isHistoric, props.templateVersion, ]);
  
  return (
    <div>
      {data.loaded
      ?   
        <div>
          <Title level={5}>{data.name}</Title>
          <p>{data.description}</p><br />
          <TemplateView
            topic={props.rid}
            nested="false"
            template={data.rTemplate}
            immediateChildren={data.rTemplate.graph}
          />
        </div>
      :
      <Spin size="large" />
    }
    </div>
  ); 
}

export default Runs;
