
import {
  Button,
  Popover,
  Col,
  Card,
  PageHeader,
  Drawer,
  Row,
  Divider, 
  Menu, 
  Dropdown,
  Alert,
  Tooltip,
  Tag,
  Typography,
  message,
  Layout,
  notification
} from "antd";

import {
  Authenticator
} from "@aws-amplify/ui-react";
import { Cache, I18n, Auth } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

import { fetchCollection, fetchSequenceSummary, checkSequence, deleteFromCollection, addSequenceToCollection } from "./Utils";
import {CredentialsContext, addSignInListener, removeSignInListener, signOut } from "./CredentialsContext";
import React, { useEffect, useState, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import {  CopyOutlined, 
          MenuOutlined, 
          LogoutOutlined, 
          UserOutlined, 
          HistoryOutlined, 
          LoginOutlined, 
          PlusOutlined, 
          AppstoreOutlined, 
          CloseCircleOutlined, 
          AppstoreAddOutlined, 
          LockOutlined, 
          LeftOutlined, 
          RightOutlined } from '@ant-design/icons';
import "antd/dist/antd.less";
import "antd/dist/antd.css";
import "./App.css";
import Endpoints from "./Run";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {basicSetup} from "@codemirror/basic-setup"
import { EditorView } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import {json /*, jsonParseLinter*/} from "@codemirror/lang-json"
import log from 'loglevel';
import { TextField } from '@aws-amplify/ui-react';

const { Footer, Content } = Layout;

const { Title, Text } = Typography;

//import locale from "react-json-editor-ajrm/locale/en";

function LoadingCard() {

  return (

      <Card title="    " 
            style={{ width: 400, margin: "10px", bordered:true }} 
            loading={true}>

        <Button
            style={{
              float: "right",
              marginRight: "10px"
            }}
            type="primary"
            htmlType="submit"
            diabled
            //onClick={() => selectedTest(value, true, inputs[key])}
          >
            Review
        </Button>
      </Card>
  );
}

function AsyncSequenceCard(props) {

  const [data, setData] = useState({name: "", description: "", owner: ""});

  useEffect(() => {

    fetchSequenceSummary(props.id).then(function(value) {
      log.info("Sequence:" + JSON.stringify(value.data));

      setData({name: value.data.Name, 
              description: value.data.Description,
              author: value.data.user['custom:display-name'],
              isPublished: value.data.isPublished
            });
    })
    .catch(function(error) {
      log.error(error);
    });

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);

  return (
      <SequenceCard name={data.name} 
                    collectionAuthor={props.collectionAuthor}
                    parentId={props.parentId} 
                    id={props.id} 
                    author={data.author} 
                    description={data.description}
                    isPublished={data.isPublished}/>
  );
}

function CollectionCard(props) {

  const [isPublished, setIsPublished] = useState(false);
  const history = useHistory();

  useEffect(() => {

    if (props.isPublished !== undefined)
      setIsPublished(props.isPublished);

    return () => {
      // Clean up the subscription
    };
  }, [isPublished, props.isPublished]);


  return (
    
      <Card
        title={props.name}
        bordered={true}
        style={{ width: 400, margin: "10px", background: "#F6883190"}}
      >
        <p>{props.description}</p>
        <br />
        <div
          key={props.id}
          style={{
            color: "gray",
            fontSize: "12px"
          }}
        >
          <p>Author: {props.author}</p>
        </div>
        <br />
        {
          (isPublished) 
          ? <Tag color="#87d068" style={{ marginLeft: "10px" }}>Published</Tag>
          : null
        }
        
        <Button
          style={{
            float: "right",
            marginRight: "10px"
          }}
          type="primary"
          htmlType="submit"
          onClick={e => {
            //e.preventDefault();
            history.push( "/collection?id=" + props.id);
          }}
        >
          View
        </Button>
      </Card>
  );
}

function AddToCollectionButton(props) {

  const [menu, setMenu] = useState(null);
  
  useEffect(() => {

    const collections = Cache.getItem("Collections"); 

    if (collections !== null) {

      if (collections.length > 0) {
        const items = [];
        collections.forEach(collection => {
        items.push(
          <Menu.Item key={collection.id} >
            {collection.name}
          </Menu.Item>
        );
        });


        setMenu (
          <Menu onClick={handleMenuClick}>
            {items}
          </Menu>
        );
      }
      else {
        setMenu (
          <Menu>
            No collections defined
          </Menu>
        ); 
      }
    }
    else {
      setMenu (
        <Menu>
          Collections not loaded
        </Menu>
      );
    }

    return () => {
      // Clean up the subscription
    };
  },[]);

  function handleMenuClick(e) {
    //message.info('Click on menu item.');
    log.info('Add sequence ' + props.sqid + " to collection " + e.key);

    addSequenceToCollection(e.key, props.sqid).then(function(value) {
      //log.info("delete collection returned " + JSON.stringify(value));
    
    })
    .catch(function(err) {
      log.error(err.response.data);
    })
    .finally(function() {
    });
  }

  return (
    <Tooltip title="Add to collection">
      <Dropdown overlay={menu}>
        <Button htmlType="submit" icon={<AppstoreAddOutlined />} />
      </Dropdown>
    </Tooltip>
  );
}

function SequenceCard(props) {

  const history = useHistory();
  const [isDeleted, setIsDeleted] = useState(false);
  const context = useContext(CredentialsContext);
  //log.info("auth: " + props.collectionAuthor);
  //log.info("me: " + context);

  var removeSequence;


  if (props.parentId !== undefined) {

    log.info("parent: " + props.parentId);


    if (props.collectionAuthor !== undefined && props.collectionAuthor === context) {

      removeSequence = <Tooltip title="Remove from collection">
        <Button type="text" size="small" icon={<CloseCircleOutlined />} 
          onClick={e => {
            log.info("removeSequence, child: " + props.id + " parent: " + props.parentId);
            deleteFromCollection(props.parentId, props.id).then(function(value) {
              log.info("delete collection returned " + JSON.stringify(value));
              setIsDeleted(true);
            })
            .catch(function(err) {
              log.error(err.response.data);
            })
            .finally(function() {
            });
          }}         
        />
      </Tooltip>
    }
  }

  return (
      <div>
      {
      (isDeleted) ? null :
      <Card
        extra= {removeSequence}
        title={props.name}
        bordered={true}
        style={{ width: 400, margin: "10px", boxShadow: "8px 8px 15px rgb(0 0 0 / 0.1)" }}
      >
        <p>{props.description}</p>
        <br />
        <div
          key={props.id}
          style={{
            color: "gray",
            fontSize: "12px"
          }}
        >
          <p>Author: {props.author}</p>
        </div>
        <br />
        <AddToCollectionButton sqid={props.id} />
        {
          (props.isPublished ) 
          ? <Tag color="#87d068" style={{ marginLeft: "10px" }}>Published</Tag>
          : null
        }
        <Button
          style={{
            float: "right",
            marginRight: "10px"
          }}
          type="run"
          htmlType="submit"
          onClick={e => {
            //e.preventDefault();
            history.push( "/sequence?id=" + props.id);
          }}
        >
          Run
        </Button>
        <Button
          style={{
            float: "right",
            marginRight: "10px"
          }}
          type="primary"
          htmlType="submit"
          onClick={e => {
            //e.preventDefault();
            history.push( "/edit?id=" + props.id);
          }}
        >
          Edit
        </Button>
      </Card>
    }
    </div>
  );
}

function CollectionView(props) {

  const collection = props.collection;
  log.info("CollectionView:" + JSON.stringify(collection));
  var elements = [];
  var keyIndex = 0;
  const url = "https://sequenceapi.com/c/" + props.collection.id;

  function processCollection(collection) {

    const shareUI = (props.isPublished) 
    ?
      <CopyToClipboard
      text={url}
      onCopy={() => {
        message.success("Copied to clipboard");
      }}
    >
      <Tooltip overlayInnerStyle={{width:"270px"}} color="green" title={url}>
        <Tag>
          <Text type="success">Share</Text>
          <CopyOutlined style={{marginRight:"10px", marginLeft:"10px"}} />
        </Tag></Tooltip>
      </CopyToClipboard>
    : null;

    elements.push(<div key={keyIndex++}><h1>{collection.name}</h1></div>);
    elements.push(<div key={keyIndex++}><p>{collection.description}{"  "}{shareUI}</p></div>);
    if (props.extra !== null) elements.push(props.extra);

    var cards = [];

    collection.children.forEach(child => {

      if (child.type === "SQ") {

        cards.push(<Col key={keyIndex++}><AsyncSequenceCard id={child.id} parentId={collection.id} collectionAuthor={collection.author}></AsyncSequenceCard></Col>);
      }
      else {

        if (cards.length > 0) {
          elements.push(<Row key={keyIndex++}>{cards}</Row>);
          cards = [];
        }
        elements.push(<Divider key={keyIndex++} />);
        elements.push(<AsyncCollectionView key={keyIndex++} id={child.id}/>);
      }
    });  

    if (cards.length > 0) {
      //log.info("p4 ");
      elements.push(<Row key={keyIndex++}>{cards}</Row>);
      cards = [];
    }
  }

  processCollection(collection);

  return (
          
    <div>
      {elements}
    </div>
  );
}


function AsyncCollectionView(props) {

  const [collection, setCollection] = useState(null);

  useEffect(() => {

    log.info("fetch: " + props.id);

    fetchCollection(props.id).then(function(value) {

      log.info("fetched collection: " + JSON.stringify(value.data));
      setCollection(value.data);
    })
    .catch(function(error) {
      log.error(error.response.data);
    });

    return () => {
      // Clean up the subscription
    };
  }, [props.id]);


  return (
          
    <div>
        {
        (collection === null) ? 
        <LoadingCard></LoadingCard> : 
        <CollectionView isPublished={collection.isPublished} collection={collection} />
        }
    </div>
  );
}
/*
function SaveOrClone(props) {
  
  const [isMine, setIsMine] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isSignedIn = useSignInStatus();

  const history = useHistory();



  if (props.template === undefined) {
    setEnabled(false);
  }
  else {
    setEnabled(true);
  }

  function save() {

    if (props.template !== null) {
      setIsSaving(true);
      updateSequence(key, props.template).then(function(value) {
        history.push( "/sequence?id=" + props.template.ID );
      })
      .catch(function(error) {
        if (error.response !== undefined)
          log.error(error.response);
        else
          log.error(error.message);
      })
      .finally(function() {
        setIsSaving(false);
      });
    }
  }

  if (isMine)
    return (
      <Button onClick={save} disabled={enabled} loading={isSaving}>
        Save
      </Button> 
    );

  return (
    <Button>
      Clone
    </Button> 
  );
}*/

export function useSignInStatus() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const context = useContext(CredentialsContext);

  useEffect(() => {

    const listener = async data => {
      setIsSignedIn(data.payload.data.isSignedIn);
    };
    addSignInListener(listener);
    
    log.info("useSignInStatus, is signedIn: " + context);
    setIsSignedIn(context);

    return () => {
      // Clean up the subscription
      removeSignInListener(listener);
    };
  }, [isSignedIn, context]);

  return isSignedIn;
}

function SQPage(props) {
//style={{ height: "calc(100vh - 220px)" }}
/*
  return (
    <div style={{minHeight:"100%", position: "relative"}} > 
      <Layout >
        <SQHeader showBack={props.showBack} />
        <div className="SQPage" style={{height:"100%"}} >

          <Content>
            {props.children}
          </Content>
        </div>
        <SQFooter />
      </Layout>
    </div>
  );
  */
  return (
    <div style={{minHeight:"100vh", display: "flex", flexDirection:"column"}} > 
      <SQHeader showBack={props.showBack} />
      <div className="SQPage" style={{flex: 1}} >

        <Content>
          {props.children}
        </Content>
      </div>
      <SQFooter />
    </div>
  );
}

function SQFooter() {

  return (
    <Footer style={{marginTop: "50px", background:"#2c2e31", height:"150px", position: "relative", bottom: 0}}>
      <Button className="footer-link" type="link" href="https://blog.sequenceapi.com" >Blog</Button>
      <Button className="footer-link" type="link" href="https://blog.sequenceapi.com/contact/" >Contact Us</Button>
      <Button className="footer-link" type="link" href="https://blog.sequenceapi.com/privacy/" >Privacy</Button>


    </Footer>
  );
}

function SQHeader(props) {

  const history = useHistory();
  const isSignedIn = useSignInStatus();
  const context = useContext(CredentialsContext);

  const logout = () => {
    log.info("logout");
    signOut(context, history);
  };  

  var showBack = () => window.history.back();
  if (props.showBack !== undefined ) {
    if (!props.showBack) {
      showBack = false;
    }
  } 

  function goHome() {
    history.push( "/");
  }

  function menuClick(item) {
    log.info("menu key:" + item.key);

    if (item.key === "0") {
      history.push( "/mysequences");
    }
    else if (item.key === "1") {
      history.push( "/newsequence");
    }
    else if (item.key === "2") {
      history.push( "/runs");
    }
    else if (item.key === "3") {
      logout();
    }
    else if (item.key === "4") {
      history.push( "/mycollections");
    }
    else if (item.key === "5") {
      history.push( "/mysecrets");
    }
    else if (item.key === "6") {
      const collectionURL = "/collection?id=" + process.env.REACT_APP_TUTORIALS;
      history.push( collectionURL );
    }
  }

  var buttons;

  const examples = <Button className="header-link" type="link" href="https://sequenceapi.com/c/c747hi" >Examples</Button>
  const blog = <Button className="header-link" type="link" href="https://blog.sequenceapi.com/sequence-api-concepts-an-overview/" >Learn more</Button>

  if (isSignedIn) {

    const menu = (

        <Menu onClick={menuClick}>
          <Menu.Item key="0" icon={<UserOutlined />}>
            My Sequences
          </Menu.Item>
          <Menu.Item key="1" icon={<PlusOutlined />}>
            New Sequence
          </Menu.Item>
          <Menu.Item key="2" icon={<HistoryOutlined />}>
            Runs
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="4" icon={<AppstoreOutlined />}>
            My Collections
          </Menu.Item>
          <Menu.Item key="5" icon={<LockOutlined />}>
            My Secrets
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="6" icon={<LockOutlined />}>
            All Tutorials
          </Menu.Item>   
          <Menu.Divider />
          <Menu.Item key="3" icon={<LogoutOutlined />}>Sign Out</Menu.Item>
        </Menu>
    );
  
    var dropdown = <>
                      {examples}
                      {blog}
                      <Dropdown key="dropdown" overlay={menu} trigger={['click']}>
                        <Button style={{marginTop: "10px"}} 
                                shape="circle" 
                                type="run"
                                icon={<MenuOutlined />} 
                        />
                      </Dropdown>
                    </>
/*
    var dropdown = <Dropdown key="dropdown" overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        <MenuOutlined />
                      </a>
                    </Dropdown>
*/
    buttons = dropdown;
  }
  else {
    buttons = <div key="headerButtons" style={{paddingTop:"8px"}}>{blog}
                  <LoginDrawer  style={{marginRight: "10px"}} 
                                key="l1" 
                                buttonType="danger" 
                                buttonText="Sign Up Free" 
                                signUpSource={props.signUpSource}
                                auth='signUp' /> 
                  <LoginDrawer  icon={<LoginOutlined />} 
                                key="l2" 
                                buttonText="Sign In" 
                                auth='signIn' />
              </div>;
  }

  const subtitle = (props.subtitle !== undefined) ? props.subtitle : "";

  return (
    <div >

      <PageHeader
        style={{paddingTop:"0px", paddingBottom:"3px"}}
        key="ph0"
        ghost={true}
        onBack={showBack}
        title={<div>
                <Button style={{height:"45px"}} onClick={goHome} type="link" >
                  <img src="/logo.png" height="40px" alt="The Sequence logo" />
                </Button>
              </div>}
        subTitle={subtitle}
        extra={buttons}
      >
      </PageHeader>
    </div>
  );
}

function LoginDrawer(props) {

  //log.info("LoginDrawer:" + props.auth);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [onConfirm, setOnConfirm] = useState(false);

  //const auth = useAuthenticator();
  const handleCancel = () => {
    //props.hide();
    if (!onConfirm) {
      setDrawerVisible(false);
    }
    log.info("Cancel " + props.buttonText);
  };

  function openDrawer() {

    try {
      window.plausible('SignInOrUp', {props: {source: props.signUpSource}});
    }
    catch (error) {
      log.error("problem pushing signup to plausible");
    }

    setDrawerVisible(true);
    log.info("Open " + props.buttonText);
  }

  return (
    
    <span>
      
      <Button size="large" style={props.style} icon={props.icon} type={props.buttonType} key="3" onClick={openDrawer}>{props.buttonText}</Button>
      <Drawer headerStyle={{paddingTop:"10px", paddingBottom:"7px"}} bodyStyle={{margin: "0px", padding:"0px"}}  title={<img  src="/logo.png" height="40px" alt="The Sequence logo" />} 
          destroyOnClose={false} visible={drawerVisible} onClose={handleCancel} width={"100%"} >
        
        <div style={{paddingTop: "80px", paddingBottom: "600px"}}  className="loginScreen">
          <LoginScreen initialState={props.auth} signUpSource={props.signUpSource} setOnConfirm={setOnConfirm}/>
        </div>
       </Drawer>

    </span>
  );
}


function LoginScreen(props) {

  const state = props.initialState;

  I18n.putVocabulariesForLanguage('en', {
    Nickname: 'Display Name',
  });

  const services = {
    
    async handleSignUp(formData) {
      let { username, password, attributes } = formData;

      try {
        log.info("Sign up attempt.");
        window.plausible('Signup', {props: {source: props.signUpSource}});
        props.setOnConfirm(true);
      }
      catch (error) {
        log.error("problem pushing signup to plausible");
      }

      return Auth.signUp({
        username,
        password,
        attributes,
      });
    },
    async handleConfirmSignUp(formData) {
      let { username, code } = formData;

      try {
        log.info("Confirming email for sign up. Send goal notification to Plausible.");
        window.plausible('Signup', {props: {source: props.signUpSource}});
      }
      catch (error) {
        log.error("problem pushing signup to plausible");
      }
      props.setOnConfirm(false);
      return Auth.confirmSignUp(username, code);
    },
  };

  const options = {loginMechanisms: ['email'],
                    initialState: state,  
                    services:services,
                    signUpAttributes:[],
                                     
                    components: {
                      // Customize `Authenticator.SignUp.FormFields`
                      SignUp: {
                        FormFields() {
                  
                          return (
                            < >
                                <Authenticator.SignUp.FormFields />
                                <TextField name="custom:display-name" placeholder="Display Name" isRequired={true} minLength={3} />
                            </>
                          );
                        },
                      },
                    },};

  return (
    <div style={{color: "#F9F7F7"}}>
      <h2 style={{color: "white",textAlign: "center"}}>Sign up now and build better APIs</h2>
      <Authenticator {...options} />
    </div>
  );
}

function SQButton(props) {

  //log.info(JSON.stringify(props));
  const history = useHistory();
  const isSignedIn = useSignInStatus();

  function handleClick(e) {
    e.preventDefault();

    if (isSignedIn) {
      if (props.href !== undefined) {
        history.push( props.href );
      }
      
      if (props.onClick !== undefined) {
        try {
          props.onClick(e); 
        }
        catch (error) {}
      }
           
    }
    else {
      log.info("not logged in");
    }
  }

  const placement = props.placement !== undefined ? props.placement : "top";

  const content = (
    <div>
      <p>You must sign in to use this feature</p>
    </div>
  );

  if (isSignedIn)
    return (
        <Button {...props} onClick={handleClick}>
          {props.children}
        </Button>
    );
  else
    return (
      <Popover placement={placement} content={content} title="Sign In" trigger="hover">
        <Button {...props} onClick={handleClick} >
          {props.children}
        </Button>
      </Popover>
    );
}

function Editor(props) {

  log.info("Editor init " + JSON.stringify(props.rTemplate.graph) );

  const [orderedTemplate, setOrderedTemplate] = useState({name:props.template.name,
                                                          description: props.template.description,
                                                          dictionary: props.template.dictionary,
                                                          outputs: props.template.outputs,
                                                          endpoints: props.template.endpoints}); 

  const [data, setData] = useState({template: orderedTemplate, 
                                    name: orderedTemplate.name, 
                                    description: orderedTemplate.description, 
                                    secrets: props.secrets,
                                    required: props.required, 
                                    rTemplate: props.rTemplate,
                                    includesMocks: props.includesMocks});
        
  const [newData, setNewData] = useState({text: orderedTemplate, isValid: false, isChecked: true});                                  

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [error, setError] = useState({visible: false, text:""});
  const [checking, setChecking] = useState(false);

  const editor = useRef();

  useEffect(() => {

    setOrderedTemplate({name:props.template.name,
                        description: props.template.description,
                        dictionary: props.template.dictionary,
                        outputs: props.template.outputs,
                        endpoints: props.template.endpoints}
                      );

    setData({template: orderedTemplate, 
            name: orderedTemplate.name, 
            description: orderedTemplate.description, 
            secrets: props.secrets,
            required: props.required, 
            rTemplate: props.rTemplate,
            includesMocks: props.includesMocks
          }
    );

    setNewData({text: orderedTemplate, isValid: false, isChecked: true});  

    return () => {
      
    };
  }, [props.updated]);
  /*
  const notify = (isValid, newText)=> {
    if (props.onInvalidation !== undefined) props.onInvalidation();
    setNewData({text: newText, isValid: isValid, isChecked: false});  
  };*/
/*
  const notify = useCallback(
    (isValid, newText) => {
      if (props.onInvalidation !== undefined) props.onInvalidation();
      setNewData({text: newText, isValid: isValid, isChecked: false});
    },
    [],
  );
*/
  useEffect(() => {

    function notify (isValid, newText) {
      if (props.onInvalidation !== undefined) props.onInvalidation();
      setNewData({text: newText, isValid: isValid, isChecked: false});  
    };

    const state = EditorState.create({
      doc: JSON.stringify(orderedTemplate, null, 2),
      extensions: [basicSetup, 
                  json(),
                  EditorView.updateListener.of((v) => {
                    if (v.docChanged) {
                      try {
                        var text = v.state.doc;
                        const parsedText = JSON.parse(text);
                        notify(true, parsedText);
                        //setData({...data, template: parsedText, isValid: true});
                      }
                      catch(err) {
                        //log.info("failed: " );
                        notify(false, v.state.doc, v);
                        //setData({...data, isValid: false});
                      }
                      
                    }
                })]
    });
    const view = new EditorView({ state, parent: editor.current });
    return () => {
      view.destroy();
    };
  }, [orderedTemplate]);

  const handleClose = () => {
    setError({visible: false, text: ""});
  };

  function doCheck() {
    setChecking(true);
    setError({visible: false, text: ""});
    checkSequence({template: newData.text})
    .then(function(value) {
      //log.info("Got load response:" + JSON.stringify(value.data));

      setData({
              name: value.data.name, 
              description: value.data.description, 
              secrets: value.data.secrets,
              required: value.data.required,
              rTemplate: value.data.template,
              template: newData.text,
              includesMocks: value.data.includesMocks
            });

      setNewData({
              text: data.template,
              isValid:false,
              isChecked: true
            });
      //log.info("doCheck:" + JSON.stringify(data));
      //setDrawerVisible(true);

      if (props.onValidation !== undefined) props.onValidation(newData.text);
    })
    .catch(function(err) {
      log.error(err);
      log.info("ERROR: " + JSON.stringify(err.response.data));
      setError({visible: true, text: JSON.stringify(err.response.data.reason)});
      if (props.onInvalidation !== undefined) props.onInvalidation();
    })
    .finally(function() { 
      setChecking(false);
    });  
  }

  function doRun() {

    if (newData.isChecked) {
      setDrawerVisible(true);
    }
  }

  const onClose = () => {
    setDrawerVisible(false);
    notification.close("runNotif");
  };

  return (

      <div>
        {
          error.visible 
          ? <Alert message={error.text} type="error" closable afterClose={handleClose} />
          : null
        }
        <Row gutter={[16, 16]}>
          <Col>
            <Button style={{marginRight:"10px"}} type="primary" htmlType="submit" onClick={doCheck} loading={checking} disabled={!newData.isValid}>
              Check
            </Button>  
            <Tooltip title="Test edits in playground mode">
              <Button style={{marginRight:"10px"}} type="run" htmlType="submit" onClick={doRun} disabled={!newData.isChecked}>
                Run
              </Button>     
            </Tooltip>
            {props.extra}  
            {data.includesMocks ? <Tag style={{marginLeft:"10px"}} color="warning">Includes mocks</Tag> : null}
          </Col>
        </Row>   
        <div style={{marginTop:"10px", borderStyle:"solid", borderWidth:"1px", borderColor:"lightgrey"}}>

          <div ref={editor}></div>
        </div>   
        <ExpandableDrawer
          title={(props.isSaved ? "Run from ID" : "Run in Playground mode")}
          visible={drawerVisible}
          onClose={onClose}
        >
          <Title level={5}>{data.name}</Title>
          <p>{data.description}</p>
          <Endpoints  secrets={data.secrets} inputs={data.required} template={data.rTemplate} required={data.required} name={data.name} 
            isSaved={props.isSaved} sqid={props.sqid} />
        </ExpandableDrawer>
      </div>

  ); 
}

function ExpandableDrawer(props) {

  const smallWidth = "40%";
  const bigWidth = "100%";

  const [drawerWidth, setDrawerWidth] = useState(smallWidth);

  function toggleWidth() {

    if (drawerWidth === smallWidth) setDrawerWidth(bigWidth);
    else setDrawerWidth(smallWidth);
  }

  return (
      <Drawer
        title={props.title}
        placement="right"
        width={drawerWidth}
        visible={props.visible}
        onClose={props.onClose}
      >
        {
          (drawerWidth === smallWidth) ?
          <Tooltip title="Expand"><Button shape="circle" size="small" icon={<LeftOutlined />} onClick={toggleWidth} /></Tooltip> :
          <Button shape="circle" size="small" icon={<RightOutlined />} onClick={toggleWidth}></Button>
        }
        
        <br /> <br /> 
        {props.children}
      </Drawer>
  ); 
}
/*
function CodeMirrorEditor(props) {

  const [text] = useState(props.text);
  const [listener] = useState(props.listener);


  const notify = useCallback(
    (isValid, newText) => {
      if (listener !== undefined) listener(isValid, newText);
    },
    [],
  );

  //const [listener] = useState(props.listener);
  const editor = useRef();

  useEffect(() => {

    const state = EditorState.create({
      doc: JSON.stringify(text, null, 2),
      extensions: [basicSetup, 
                  json(),
                  EditorView.updateListener.of((v) => {
                    if (v.docChanged) {
                      //log.info("chnage: " + v.state.doc);
                      try {

                        var text = v.state.doc;
                        const parsedText = JSON.parse(text);
                        notify(true, parsedText);
                      }
                      catch(err) {
                        //log.info("failed: " );
                        notify(false, v.state.doc);
                      }
                      
                    }
                })]
    });
    const view = new EditorView({ state, parent: editor.current });
    return () => {
      view.destroy();
    };
  }, [text, notify]);

  return <div ref={editor}></div>

  //return <section ref={editorRef}/>;
}*/


export {  SequenceCard, 
          AsyncSequenceCard, 
          LoadingCard, 
          SQHeader,
          SQPage,
          SQButton, 
          CollectionView, 
          AsyncCollectionView, 
          Editor, 
          CollectionCard, 
          ExpandableDrawer, 
          LoginDrawer,
          AddToCollectionButton };
