import React, {useState, useContext } from "react";
import { Button, Tag, message, Typography, Tooltip } from "antd";

import { publishSequence } from "./Utils";
import { PublishContext } from "./PublishContext";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from '@ant-design/icons';
import { CredentialsContext } from "./CredentialsContext";

import log from 'loglevel';
const { Text } = Typography;

function PublishButton(props) {

  const [isPublishing, setIsPublishing] = useState(false);
  const [publishState, setPublishState] = useContext(PublishContext); 
  const user = useContext(CredentialsContext);

  log.info("publishState: " + JSON.stringify(publishState));

  function publish() {

    setIsPublishing(true);
    publishSequence(props.id).then(function(value) {
      log.info("PUBLISHED by Publish: " + JSON.stringify(value.data));

      var newState = {
        ...publishState,
        setAllowPublish: false,
        publishedVersion: value.data.version
      };

      setPublishState(newState);  
    })
    .catch(function(error) {
      if (error.response !== undefined)
        console.error(error.response);
      else
        console.error(error.message);
    })
    .finally(function() {
      setIsPublishing(false);
    });
  }

  return (
    <>
      {
        (user === publishState.author && publishState.setAllowPublish)
        ?
          <Button style={{ marginLeft: "10px" }} onClick={publish} disabled={!publishState.setAllowPublish} loading={isPublishing}>
            Publish v{publishState.savedVersion}
          </Button>    
        :
          null
      }
    </>
  ); 
}

function ShareField(props) {

  const [publishState, ] = useContext(PublishContext); 

  const type = (props.type !== undefined) ? props.type :'s';

  const url = "https://sequenceapi.com/" + type + "/" + props.id;

  return (

    <>
      {
        (publishState.publishedVersion > -1) 
        ?
          <CopyToClipboard
            text={url}
            onCopy={() => {
              message.success("Copied to clipboard");
            }}
          >
            <Tooltip overlayInnerStyle={{width:"280px"}} color="green" title={url}>
              <Tag>
                <Text type="success">Share</Text>
                <CopyOutlined style={{marginRight:"10px", marginLeft:"10px"}} />
              </Tag></Tooltip>
          </CopyToClipboard>
        :
          null
      }
    </>

  ); 
}

function PublishTag() {

  const [publishState, ] = useContext(PublishContext); 

  return (
    <>
      {
        (publishState.publishedVersion > -1) 
        ?     
          <Tag color="#87d068" style={{ marginLeft: "10px" }}>Published v{publishState.publishedVersion}</Tag> 
        : 
          <Tag color="#f50" style={{ marginLeft: "10px" }}>Unpublished</Tag>
      } 
    </>
  ); 
}


export {PublishButton, PublishTag, ShareField};
